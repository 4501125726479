import React from 'react';
import Button from 'components/Button';
import './index.css';

interface PendingChangesModalProps {
  isVisible: boolean;
  onClose: () => void;
  onContinue: () => void;
}

const PendingChangesModal: React.FC<PendingChangesModalProps> = ({ isVisible, onClose, onContinue }) => {
  const handleContinue = () => {
    onClose();
    onContinue();
  };

  if (!isVisible) return null;

  return (
    <div className="modal-overlay">
      <div className="modal-content-pending-changes">
        <h4>Leave page?</h4>

        <p>You have some unsaved changes to the patient data. Do you want to leave without saving?</p>

        <div className="buttons-container">
          <Button onClick={onClose} styles={{ opacity: 0.5, padding: '15px 50px' }}>
            Stay on this page
          </Button>
          <Button onClick={handleContinue} styles={{ padding: '15px 50px' }}>Leave without saving</Button>
        </div>
      </div>
    </div>
  );
};

export default PendingChangesModal;
