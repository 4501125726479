import { useState, useEffect } from 'react';
import { useForm, SubmitHandler } from 'react-hook-form';
import { useQuery, useMutation } from '@apollo/client';
import { Location } from 'history';

import { GET_PLAN_DEFINITIONS } from 'containers/PatientProfilePage/components/OrderTest/queries';
import SearchResults from 'containers/SearchResults';
import LoadingErrorWrapper from 'components/LoadingErrorWrapper';
import Navigation from 'components/Navigation';
import { Patient, PlanDefinition, ReportsPageProps, SearchNavigationState } from 'types';

import TestStatusDropdown from './components/TestStatusDropdown';
import TestTypeDropdown from './components/TestTypeDropdown';
import LastUpdatedDropdown from './components/LastUpdatedDropdown';

import { READ_CACHED_RESULTS, SEARCH_PATIENT_REPORT, WRITE_CACHED_RESULTS } from './queries';
import { formatSearchParams } from './utils';
import { SearchBarContainer, SearchButton } from './styles';

const ReportPage = ({ location }: { location: Location }) => {
  const [searchResponse, setSearchResults] = useState<Patient[] | null>(null);
  const [planDefinitions, setPlanDefinitions] = useState<PlanDefinition[] | undefined>();
  const [searchParametersData, setSearchParametersData] = useState<ReportsPageProps | null>(null);
  const [activeSortColumn, setActiveSortColumn] = useState<string | undefined>(undefined);
  const [sortDirection, setSortDirection] = useState<string | undefined>(undefined);

  const state = location.state as SearchNavigationState;

  const {
    control,
    handleSubmit,
    formState: { errors },
    setValue,
  } = useForm<ReportsPageProps>({
    mode: 'onSubmit',
  });

  const { loading: loadingPlans, error: errorPlans } = useQuery(GET_PLAN_DEFINITIONS, {
    fetchPolicy: 'network-only',
    onCompleted: ({ getPlanDefinitions }) => {
      setPlanDefinitions(getPlanDefinitions);
    },
    onError: (err) => console.error(err),
  });

  const [searchPatient, { error: searchPatientError, loading: searchPatientLoading, client }] = useMutation(SEARCH_PATIENT_REPORT, {
    fetchPolicy: 'network-only',
    onCompleted: ({ searchPatientReport }) => {
      setSearchResults(searchPatientReport);
      client.writeQuery({
        query: WRITE_CACHED_RESULTS,
        data: { searchPatientReport, searchParametersData },
      });
    },
    onError: (err) => console.error(err),
  });

  useEffect(() => {
    if (state?.getFromCache) {
      const data = client.readQuery({
        query: READ_CACHED_RESULTS,
      });
      if (data?.searchParametersData) {
        setSearchParametersData(data.searchParametersData);
        setValue('testType', data.searchParametersData.testType);
        setValue('testStatus', data.searchParametersData.testStatus);
        setValue('lastUpdated', data.searchParametersData.lastUpdated);
        setActiveSortColumn(data.searchParametersData.sortColumn);
        setSortDirection(data.searchParametersData.sortDirection);
      }
      if (data?.searchPatientReport) {
        setSearchResults(data.searchPatientReport);
      }
      window.history.replaceState({}, document.title);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [client, state]);

  const onSubmit: SubmitHandler<ReportsPageProps> = (data) => {
    setDefaultSortProps();
    const mergedParameters = { ...data, sortColumn: activeSortColumn ?? 'Kit Status', sortDirection: sortDirection ?? 'DESC' } as ReportsPageProps;
    setSearchParametersData(mergedParameters);
    searchPatient({
      variables: getSearchVariables(mergedParameters),
    });
  };

  const onSearch = handleSubmit(onSubmit);

  const getSearchVariables = (params: ReportsPageProps) => {
    return {
      searchParams: formatSearchParams(params),
    };
  };

  const setDefaultSortProps = () => {
    if (!activeSortColumn) {
      setActiveSortColumn('Kit Status');
    }
    if (!sortDirection) {
      setSortDirection('DESC');
    }
  };

  const onResultsTableSort = (clickedColumn: string, clickedDirection: string) => {
    setActiveSortColumn(clickedColumn);
    setSortDirection(clickedDirection);

    const searchParamsWithSortProps = { ...searchParametersData, sortColumn: clickedColumn, sortDirection: clickedDirection } as ReportsPageProps;
    setSearchParametersData(searchParamsWithSortProps);
    searchPatient({
      variables: getSearchVariables(searchParamsWithSortProps),
    });
  };

  return (
    <Navigation
      page="reports"
      render={() => (
        <>
          <SearchBarContainer>
            <div style={{ display: 'flex' }}>
              <TestTypeDropdown control={control} planDefinitions={planDefinitions} errors={errors} />
              <TestStatusDropdown control={control} />
              <LastUpdatedDropdown control={control} errors={errors} />
              <SearchButton onClick={onSearch} data-testid="submit-button">
                Search
              </SearchButton>
            </div>
          </SearchBarContainer>
          <LoadingErrorWrapper loading={searchPatientLoading || loadingPlans} error={searchPatientError || errorPlans} styles={{ width: '100%', height: '100vh' }}>
            <SearchResults searchResults={searchResponse} resultsTableSortProps={{ column: activeSortColumn, direction: sortDirection, onSort: onResultsTableSort }} />
          </LoadingErrorWrapper>
        </>
      )}
    />
  );
};

export default ReportPage;
