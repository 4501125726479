// Adapted from
// https://github.com/boxwise/boxtribute/blob/a9abcea8242066eb591419401e899418465724ed/react/src/ApolloWrapper.tsx

import React, { useState, useEffect } from 'react';
import * as Sentry from '@sentry/react';
import { ApolloClient, InMemoryCache, createHttpLink, ApolloProvider, ApolloLink } from '@apollo/client';
import { useAuth0 } from '@auth0/auth0-react';
import { RetryLink } from '@apollo/client/link/retry';
import { onError } from '@apollo/client/link/error';
import { setContext } from '@apollo/client/link/context';

interface Props {
  children: React.ReactNode;
}

const ApolloWrapper = ({ children }: Props) => {
  const { isAuthenticated, getAccessTokenSilently, user, logout } = useAuth0();
  const [auth0Token, setAuth0Token] = useState<String>('');

  const httpLink = createHttpLink({
    uri: window?.config?.REACT_APP_GRAPHQL_ENDPOINT ?? process.env.REACT_APP_GRAPHQL_ENDPOINT,
    fetch: async (input, init) => {
      console.info(init);
      const changedBody = JSON.parse((init as any).body)
      const reqToSend = {
        ...init, 
        body: JSON.stringify({ 
          ...changedBody, 
          accessToken: auth0Token })
      }
      const res = await fetch(input, reqToSend);
      console.info(JSON.stringify(res));
      return res;
    },
  });

  const retryLink = new RetryLink({
    delay: {
      initial: 1000,
      max: Infinity,
      jitter: true,
    },
    attempts: {
      max: 5,
      retryIf: (error, operation) => {
        const shouldRetry = !!error;
        if (shouldRetry) {
          Sentry.captureException(error, {
            extra: {
              event: 'ApolloWrapper - RetryLink.retryIf',
              message: `Operation: ${JSON.stringify(operation, null, 2)}`
            },
          });
        }
        return shouldRetry;
      },
    },
  });

  const errorLink = onError(({ graphQLErrors, networkError, operation }) => {
    if (graphQLErrors) {
      graphQLErrors.forEach((error) => {
        const { message, extensions, path } = error;
        const formattedMessage = JSON.stringify(message, null, 2);
        const formattedPath = JSON.stringify(path, null, 2);
        const formattedOperation = JSON.stringify(operation, null, 2);
        const extensionsCode = extensions?.code;
        const newError = `[GraphQL error]: Message: ${formattedMessage}, \nPath: ${formattedPath}, \nOperation: ${formattedOperation}, \nExtensionsCode: ${extensionsCode}`;
        console.error(newError);
        Sentry.captureException(error, {
          extra: {
            event: 'ApolloWrapper - GraphqlError',
            message: newError,
          },
        });
        if (extensionsCode === 'UNAUTHENTICATED') {
          logout({ returnTo: window.location.origin })
        }
      });
    }
    if (networkError) {
      console.error(`[Network error]: ${networkError}`);
      Sentry.captureException(networkError, {
        extra: {
          event: 'ApolloWrapper - NetworkError',
          message: networkError.message,
        },
      });
    }
  });

  const cache = new InMemoryCache({
    typePolicies: {
      Patient: {
        fields: {
          id(id: string) {
            return id;
          },
        },
        merge(existing, incoming, { mergeObjects }) {
          return mergeObjects(existing, incoming);
        },
      },
    },
  });

  const auth0Link = setContext((_, { headers, ...rest }) => {
    if (!auth0Token) return { headers, ...rest };

    return {
      ...rest,
      headers: {
        ...headers,
        Authorization: `Bearer ${auth0Token}`,
      },
    };
  });

  useEffect(() => {
    const getAuth0Token = async () => {
      const token = isAuthenticated ? await getAccessTokenSilently() : '';
      if (token) {
        const isImawareEmployee = user?.email?.includes('imaware.health').toString() ?? 'false';
        console.log(`ApolloWrapper - isImawareEmployee: ${isImawareEmployee} ${new Date().toISOString()}`);
        localStorage.setItem('isImawareEmployee', isImawareEmployee);
        localStorage.setItem('userEmail', user?.email ?? '');
        setAuth0Token(token);
      }
    };

    getAuth0Token().catch((ex) => {
      console.error('getAuth0Token error', ex);
    });
  }, [isAuthenticated, getAccessTokenSilently, user?.email]);

  const client = new ApolloClient({
    cache,
    link: ApolloLink.from([errorLink, retryLink, auth0Link, httpLink]),
  });

  return <ApolloProvider client={client}>{children}</ApolloProvider>;
};

export default ApolloWrapper;
