import { CSSObjectWithLabel } from 'react-select';

export const options = [
  {
    value: 'kitId',
    label: 'Kit ID / Activation ID',
  },
  {
    value: 'name',
    label: 'First Name',
  },
  {
    value: 'family',
    label: 'Last Name',
  },
  {
    value: 'telecom',
    label: 'Email',
  },
  {
    value: 'trackingNumber',
    label: 'Tracking Number',
  },
];

export const selectStyles = {
  control: (provided: CSSObjectWithLabel) => ({
    ...provided,
    border: 'none',
    height: '42px',
    borderRadius: '0px 4px 4px 0',
  }),
  valueContainer: (provided: CSSObjectWithLabel) => ({
    ...provided,
    fontFamily: 'Roboto',
    fontSize: '14px',
    lineHeight: '24px',
    padding: '2px 15px',
  }),
  indicatorSeparator: (provided: CSSObjectWithLabel) => ({
    ...provided,
    display: 'none',
  }),
  indicatorsContainer: (provided: CSSObjectWithLabel) => ({
    ...provided,
    marginRight: '7px',
  }),
  placeholder: (provided: CSSObjectWithLabel) => ({
    ...provided,
    fontFamily: 'Roboto',
    fontSize: '14px',
    lineHeight: '24px',
    color: 'rgba(0, 0, 0, 0.7)',
  }),
  singleValue: (provided: CSSObjectWithLabel) => ({
    ...provided,
    fontFamily: 'Roboto',
    fontSize: '14px',
    lineHeight: '24px',
    color: 'rgba(0, 0, 0, 0.7)',
  }),
  menuList: (provided: CSSObjectWithLabel) => ({
    ...provided,
    fontFamily: 'Roboto',
    fontSize: '14px',
    lineHeight: '24px',
    color: 'rgba(0, 0, 0, 0.7)',
  }),
};
