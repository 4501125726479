import { CSSObjectWithLabel } from 'react-select';

export const options = [
  {
    value: window?.config?.REACT_APP_PARTNER_TEST_ID ?? process.env.REACT_APP_PARTNER_TEST_ID,
    label: 'Parsley Baseline Panel',
  },
];

export const selectStyles = {
  indicatorSeparator: (provided: CSSObjectWithLabel) => ({
    ...provided,
    display: 'none',
  }),
  indicatorsContainer: (provided: CSSObjectWithLabel) => ({
    ...provided,
    marginRight: '12px',
  }),
  placeholder: (provided: CSSObjectWithLabel) => ({
    ...provided,
    fontFamily: 'Lato',
    fontSize: '14px',
    lineHeight: '17px',
    letterSpacing: '0.64px',
    backgroundColor: 'red',
  }),
  singleValue: (provided: CSSObjectWithLabel) => ({
    ...provided,
    fontFamily: 'Lato',
    fontSize: '14px',
    lineHeight: '17px',
    letterSpacing: '0.64px',
  }),
  menuList: (provided: CSSObjectWithLabel) => ({
    ...provided,
    fontFamily: 'Lato',
    fontSize: '14px',
    lineHeight: '17px',
    letterSpacing: '0.64px',
  }),
  valueContainer: (provided: CSSObjectWithLabel) => ({
    ...provided,
    padding: '12px 16px',
  }),
  control: (provided: CSSObjectWithLabel) => ({
    ...provided,
    border: '1px solid #B9C8D3',
  }),
};
