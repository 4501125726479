import styled from 'styled-components';

interface ResultRowProps {
  includesTracking?: boolean;
}

export default styled.div`
  background: #f7f9fb;
  border-radius: 4px;
  display: flex;
  width: 100%;
  height: 49px;
  align-items: center;
  :nth-of-type(odd) {
    background: #ecf1f5;
  }
`;

export const RowColumn = styled.div<ResultRowProps>`
  font-family: Lato;
  font-size: 14px;
  line-height: 17px;
  letter-spacing: 0.01em;
  color: #4c5862;
  flex-basis: ${({ includesTracking }) => (includesTracking ? '11%' : '20%')};
  flex: 1;
  :first-of-type {
    margin-left: 48px;
  }
  display: flex;
  flex-direction: column;
  word-break: break-all;
`;

export const TimeStamp = styled.span`
  font-size: 10px;
`;