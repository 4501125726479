import styled from 'styled-components';

export default styled.div`
  width: 325px;
  margin-right: 10px;
`;

export const SearchError = styled.p`
  color: #d80000;
  font-family: Lato;
  font-weight: 700;
  font-size: 12px;
  letter-spacing: 0.415385px;
  margin-top: -20px;
  margin-bottom: 4px;
`;
