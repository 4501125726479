import React from 'react';
import ButtonContainer from './styles';

const Button = ({
  children,
  onClick,
  dataTestId,
  secondary,
  styles,
  disabled
}: {
  children: React.ReactChild;
  // eslint-disable-next-line no-unused-vars
  onClick: (evt?: React.MouseEvent<HTMLButtonElement>) => void;
  dataTestId?: string;
  secondary?: boolean;
  disabled?: boolean;
  styles?: {
    padding?: string;
    fontSize?: string;
    lineHeight?: string;
    opacity?: number;
  };
}) => {
  return (
    <ButtonContainer
      onClick={onClick}
      data-testid={dataTestId}
      secondary={secondary}
      styles={styles}
      disabled={disabled}
    >
      {children}
    </ButtonContainer>
  );
};

export default Button;
