import { Control, Controller } from 'react-hook-form';
import Select from 'react-select';

import { selectStyles } from './utils';
import DropdownContainer from './styles';
import { PlanDefinition } from 'types';

const TestTypeDropdown = ({ control, planDefinitions }: { control: Control<{ testType: { value: string; label: string } }>; planDefinitions?: PlanDefinition[] }) => {
  const options = planDefinitions?.map((pd) => {
    let label = pd.label;
    let prefix = '';
    for (let i = 0; i < pd.identifier.length; i++) {
      let ident = pd.identifier[i];
      if (ident.system == 'https://imaware.health/skus') {
        prefix = ident.value;
      }
    }
    if (prefix !== '') {
      label += ' (' + prefix + ')';
    }
    return { value: pd.id, label: label };
  });
  return (
    <DropdownContainer>
      <Controller
        control={control}
        name="testType"
        render={({ field: { onChange, onBlur, value } }) => (
          <>
            <Select
              options={options}
              onChange={onChange}
              onBlur={onBlur}
              value={value}
              menuPlacement="auto"
              menuPosition="fixed"
              placeholder="Select Test..."
              styles={selectStyles}
            />
          </>
        )}
      />
    </DropdownContainer>
  );
};

export default TestTypeDropdown;
