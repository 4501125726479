import Button from 'components/Button';

interface Props {
  onDownload: () => void;
  loading: boolean;
}
const DownloadCeliacKitsBtn = (props: Props) => {
  const { onDownload, loading } = props;

  return (
    <div style={{marginLeft: "10px"}}>    
    <Button disabled={loading} onClick={onDownload} dataTestId="export-celiac-button">
      {loading ? 'Loading Report' : 'Export Celiac Kits'}
    </Button>
    </div>
  );
};

export default DownloadCeliacKitsBtn;
