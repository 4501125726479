import Navigation from "components/Navigation";
import CreatePatientContainer from './CreatePatientPage/styles';

import Button from 'components/Button';

const DebugSentry = () => {

  const throwError = () => { 
    throw new Error('Test partner-portal error');
  };

  const sentryRelease =
    window?.config?.REACT_APP_RELEASE_TAG ?? process.env.REACT_APP_RELEASE_TAG;
  const sentryEnvironment = 
    (window?.config?.REACT_APP_ENVIRONMENT ?? process.env.REACT_APP_ENVIRONMENT) || 'local';

  return (
    <Navigation
        page="create"
        render={() => (
          <>
            <CreatePatientContainer createSuccess={false}>
              <Button onClick={throwError}>
                <>
                  Throw error (v: {sentryRelease}, env: {sentryEnvironment})
                </>
              </Button>
            </CreatePatientContainer>
          </>
        )}
      />
  );
};

export default DebugSentry;
