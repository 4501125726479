import gql from 'graphql-tag';

export const ORDER_TEST = gql`
  mutation OrderTest($testParams: TestParams!) {
    orderTest(testParams: $testParams)
  }
`;

export const GET_PLAN_DEFINITIONS = gql`
  query GetPlanDefinitions {
    getPlanDefinitions {
      id 
      label
      identifier {
        system
        value
      }
    }
  }
`;