import { Control, Controller, FieldErrors } from 'react-hook-form';
import Select from 'react-select';

import { ReportsPageProps } from 'types';
import DropdownContainer from './styles';
import { options } from './utils';
import { Option, ValueContainer, getStyles } from 'components/SharedDropdownComponents';

const LastUpdatedDropdown = ({ control, errors }: { control: Control<ReportsPageProps>; errors: FieldErrors<ReportsPageProps> }) => (
  <DropdownContainer>
    <Controller
      control={control}
      name="lastUpdated"
      render={({ field: { onChange, onBlur, value } }) => (
        <Select
          options={options as any}
          onChange={onChange}
          onBlur={onBlur}
          value={value}
          menuPlacement="auto"
          menuPosition="absolute"
          placeholder="Last Updated"
          components={{
            ValueContainer,
            Option,
          }}
          styles={getStyles(Object.keys(errors).length !== 0)}
          hideSelectedOptions={false}
          closeMenuOnSelect={false}
        />
      )}
    />
  </DropdownContainer>
);

export default LastUpdatedDropdown;
