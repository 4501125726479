import styled from 'styled-components';
import backIcon from 'assets/images/back-icon.png';

export default styled.div`
  background: #e5e5e5;
  .flex {
    display: flex;
  }
`;

export const PatientInformationContainer = styled.div`
  margin: 24px 24px 16px 24px;
  width: 100%;
`;

export const BackButton = styled.div`
  background: url(${backIcon}) no-repeat;
  padding-left: 26px;
  background-position: 9px;
  font-family: Lato;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 32px;
  margin-top: 16px;
  margin-bottom: 10px;
  :hover {
    cursor: pointer;
  }
`;

export const PatientName = styled.div`
  margin-bottom: 15px;
  font-family: Lora;
  font-size: 28px;
  line-height: 36px;
  font-weight: 500;
  letter-spacing: 0.01em;
`;

export const SidePanelContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-right: 46px;
  margin-top: 134px;
  background: #e5e5e5;
`;
