import styled from 'styled-components';

export const LabelContainer = styled.div`
  background-color: #f7f9fb;
  padding: 15px 15px;
  border-radius: 4px 4px 0px 0px;
`;

export const FormLabel = styled.h4`
  font-size: 14px;
  text-transform: uppercase;
  font-weight: 700;
  font-family: Lato;
  color: rgb(89, 116, 138);
  margin: 0;
`;

export const FormContainer = styled.form`
  background-color: white;
  border-radius: 0 0 4px 4px;
`;

export const FormSection = styled.div`
  display: flex;
  flex-direction: column;
  border-top: 1px solid #ecf1f5;
  padding-bottom: 30px;
`;

export const FormRow = styled.div`
  display: flex;
  flex-direction: row;
  padding: 10px 15px;
`;

export const FormSubheader = styled.h3`
  font-family: Roboto;
  font-weight: 500;
  font-size: 16px;
  color: rgb(47, 47, 47);
  margin-left: 15px;
  letter-spacing: 0.8px;
  margin-bottom: 0;
`;

export const CreateSuccessContainer = styled.div`
  background: #ffffff;
  border: 2px solid #ffffff;
  box-sizing: border-box;
  border-radius: 4px;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 40px 0;
  width: 600px;
  button:first-of-type {
    margin-bottom: 18px;
  }
`;

export const CreateSuccessCopy = styled.div`
  font-family: Lora;
  font-style: normal;
  font-weight: bold;
  font-size: 26px;
  line-height: 32px;
  color: #000000;
  text-align: center;
  margin: 18px 0 28px 0;
`;
