import React from 'react';
import { useHistory } from 'react-router-dom';
import { Auth0Provider, AppState } from '@auth0/auth0-react';

interface Props {
  children: React.ReactNode;
}

const Auth0ProviderWithHistory = ({ children }: Props) => {
  const domain =
    window?.config?.REACT_APP_AUTH0_DOMAIN ??
    process.env.REACT_APP_AUTH0_DOMAIN;
  const clientId =
    window?.config?.REACT_APP_AUTH0_CLIENT_ID ??
    process.env.REACT_APP_AUTH0_CLIENT_ID;
  const organization =
    window?.config?.REACT_APP_AUTH0_ORG_ID ??
    process.env.REACT_APP_AUTH0_ORG_ID;
  const audience =
    window?.config?.REACT_APP_AUTH0_AUD ?? process.env.REACT_APP_AUTH0_AUD;
  const scope = 'openid profile email';
  const history = useHistory();

  const onRedirectCallback = (appState?: AppState) => {
    history.push(appState?.returnTo || window.location.pathname);
  };

  return (
    <Auth0Provider
      domain={domain}
      clientId={clientId}
      redirectUri={window.location.origin}
      onRedirectCallback={onRedirectCallback}
      organization={organization}
      audience={audience}
      scope={scope}
      useRefreshTokens={true}
      cacheLocation={"localstorage"}
    >
      {children}
    </Auth0Provider>
  );
};

export default Auth0ProviderWithHistory;
