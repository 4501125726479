import * as React from 'react';
import { ErrorMessageSpan } from './styles';

const ErrorMessage = ({ errorMessage }: { errorMessage?: string }) => (
  <ErrorMessageSpan>
    {errorMessage ? errorMessage : 'This field is required'}
  </ErrorMessageSpan>
);

export default ErrorMessage;
