import React from 'react';
import Button from 'components/Button';
import './index.css';

interface AddressModalProps {
  isVisible: boolean;
  addressString: string | null;
  addressError: string | null;
  onClose: () => void;
  onContinue: () => void;
}

const AddressModal: React.FC<AddressModalProps> = ({ isVisible, addressString, addressError, onClose, onContinue }) => {
  const handleContinue = () => {
    onClose();
    onContinue();
  };

  const getFormattedAddressError = () => {
    if (!addressError) {
      return addressError;
    }
    return (addressError.split('Hints: ')[1] || '').replace('"}', '');
  };

  if (!isVisible) return null;

  return (
    <div className="modal-overlay">
      <div className="modal-content-address">
        <h4>We were not able to validate the address you provided:</h4>
        <p className="address">{addressString}</p>
        <p>Error: {getFormattedAddressError()}</p>

        <p>Please double check that you inputted this address correctly before continuing.</p>

        <div className="buttons-container">
          <Button onClick={onClose}>Edit Address</Button>
          <Button onClick={handleContinue} styles={{ opacity: 0.5 }}>
            Continue With This Address
          </Button>
        </div>
      </div>
    </div>
  );
};

export default AddressModal;
