import { useState } from 'react';

import Navigation from 'components/Navigation';
import PatientForm from 'components/PatientForm';

import CreatePatientContainer, { CreatePatientHeader } from './styles';

const CreatePatientPage = () => {
  const [createSuccess, setCreateSuccess] = useState<null | boolean>(null);

  const onCreateSuccess = () => setCreateSuccess(true);

  return (
    <Navigation
      page="create"
      render={() => (
        <>
          <CreatePatientContainer createSuccess={createSuccess}>
            {!createSuccess && (
              <CreatePatientHeader>Create New Patient </CreatePatientHeader>
            )}
            <PatientForm onCreateSuccess={onCreateSuccess} />
          </CreatePatientContainer>
        </>
      )}
    />
  );
};

export default CreatePatientPage;
