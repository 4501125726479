import { useState, useEffect } from 'react';

import searchIcon from 'assets/images/large-search-icon.png';
import { FormattedResultRow, KitStatus, Patient, ReportResult } from 'types';

import ResultRow from './components/ResultRow';

import ResultsContainer, { ResultHeaderText, SearchCopy, SearchHeader, ResultsHeader, HeaderColumn, ResultBanner, NoResultsBanner, ResultRowContainer, ResultsBox } from './styles';
import { formatDate, formatPatient } from './utils';
import { ApolloError } from '@apollo/client';
import SortableColumnHeader from '../../components/SortableColumnHeader';

const SearchCopyContainer = ({ searchPatientError, results }: { searchPatientError?: ApolloError | null; results?: FormattedResultRow[] | null }) => {
  if (!!searchPatientError || results?.length === 0) {
    return (
      <ResultsBox>
        <img src={searchIcon} />
        <SearchHeader>No results found. Please try again.</SearchHeader>
      </ResultsBox>
    );
  }

  return (
    <ResultsBox>
      <img src={searchIcon} />
      <SearchHeader>Begin your search above</SearchHeader>
      <SearchCopy>
        {window.location.pathname === '/reports' ? (
          <>
            You can search for patient data by by filtering by test type, <br /> test status, and last updated date.
          </>
        ) : (
          <>
            You can search for patient data by entering their kit ID, <br /> first name, last name, email, or tracking code.
          </>
        )}
      </SearchCopy>
    </ResultsBox>
  );
};

const SearchResults = ({
  searchResults,
  searchPatientError,
  resultsTableSortProps,
}: {
  searchResults: Patient[] | ReportResult[] | null;
  searchPatientError?: ApolloError | null;
  resultsTableSortProps?: { column: string | undefined; direction: string | undefined; onSort?: Function };
}) => {
  const isReports = window.location.pathname === '/reports';
  const activeSortColumn = resultsTableSortProps?.column;
  const sortDirection = resultsTableSortProps?.direction;

  const handleSort = (clickedColumn: string, clickedDirection: string) => {

    resultsTableSortProps?.onSort && resultsTableSortProps.onSort(clickedColumn, clickedDirection);
  };
  const [mappableRows, setMappableRows] = useState<FormattedResultRow[] | null>(null);
  useEffect(() => {
    /* parse searchResults based on the url */
    if (searchResults) {
      const rows: FormattedResultRow[] = [];
      if (isReports) {
        const reportResults = searchResults as ReportResult[];
        reportResults.forEach((row) => {
          rows.push({
            email: row.email,
            id: row.patient_id,
            kitId: row.kit_id,
            kitStatus: [{ status: row.kit_status, timestamp: row.last_updated }],
            testType: row.test_type,
            name: row.patient_name,
          });
        });
        setMappableRows(rows);
      } else {
        const patientResults = searchResults as Patient[];
        patientResults.forEach((row) => {
          if (row.testHistory?.length) {
            row.testHistory.forEach((history) => {
              rows.push({
                kitStatus: history.kitStatus ?? ([{}] as KitStatus[]),
                kitId: history.kitId,
                testType: history.testType,
                ...formatPatient(row),
              });
            });
          } else {
            rows.push({
              ...formatPatient(row),
            });
          }
        });
      }
      setMappableRows(rows);
    }
  }, [searchResults, isReports]);

  const renderResults = () =>
    mappableRows?.map((result) => <ResultRow result={result} key={result.kitId} date={formatDate(result.kitStatus?.[0].timestamp)} fromReports={isReports} />);

  const RenderResultsHeader = () => {
    return (
      <ResultsHeader>
        <SortableColumnHeader column="Patient Name" activeSortColumn={activeSortColumn!} sortDirection={sortDirection!} onSort={handleSort} />
        <SortableColumnHeader column="Email" activeSortColumn={activeSortColumn!} sortDirection={sortDirection!} onSort={handleSort} />
        <SortableColumnHeader column="Test Type" activeSortColumn={activeSortColumn!} sortDirection={sortDirection!} onSort={handleSort} />
        <SortableColumnHeader column="Kit ID" activeSortColumn={activeSortColumn!} sortDirection={sortDirection!} onSort={handleSort} />
        <SortableColumnHeader column="Kit Status" activeSortColumn={activeSortColumn!} sortDirection={sortDirection!} onSort={handleSort} />
        <HeaderColumn>Patient Record</HeaderColumn>
      </ResultsHeader>
    );
  };

  if (!mappableRows) {
    return (
      <ResultsContainer>
        <SearchCopyContainer searchPatientError={searchPatientError} results={mappableRows} />
      </ResultsContainer>
    );
  }

  return (
    <>
      {!isReports && mappableRows.length > 20 ? (
        <ResultBanner>There are {mappableRows.length} returned results. Please deselect as many categories as possible to narrow your search</ResultBanner>
      ) : null}
      {mappableRows.length === 0 ? <NoResultsBanner>Your search had no results. Please broaden your search and try again.</NoResultsBanner> : null}
      <ResultHeaderText>{mappableRows?.length ? `${mappableRows.length} results found` : 'Results'}</ResultHeaderText>
      <ResultsContainer>
        <ResultRowContainer>
          {mappableRows?.length ? (
            <>
              {RenderResultsHeader()}
              {renderResults()}
            </>
          ) : (
            <SearchCopyContainer searchPatientError={searchPatientError} results={mappableRows} />
          )}
        </ResultRowContainer>
      </ResultsContainer>
    </>
  );
};

export default SearchResults;
