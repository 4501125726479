import React from 'react';
import Button from 'components/Button';
import './index.css';

interface ConfirmChangesModalProps {
  isVisible: boolean;
  onClose: () => void;
  onContinue: () => void;
}

const ConfirmChangesModal: React.FC<ConfirmChangesModalProps> = ({ isVisible, onClose, onContinue }) => {
  const handleContinue = () => {
    onClose();
    onContinue();
  };

  if (!isVisible) return null;

  return (
    <div className="modal-overlay">
      <div className="modal-content-confirm-changes">
        <h4>Confirm Changes</h4>

        <p>Are you sure you want to make these changes to the patient data?</p>

        <div className="buttons-container">
          <Button onClick={onClose} styles={{ opacity: 0.5 }}>Cancel</Button>
          <Button onClick={handleContinue}>
            Yes
          </Button>
        </div>
      </div>
    </div>
  );
};

export default ConfirmChangesModal;
