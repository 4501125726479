import { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { Location } from 'history';
import { useForm, SubmitHandler } from 'react-hook-form';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { useMutation } from '@apollo/client';

import Input from 'components/Input';
import Button from 'components/Button';
import LoadingErrorWrapper from 'components/LoadingErrorWrapper';

import checkmark from 'assets/images/checkmark.png';
import { Patient, PatientInput } from 'types';

import { UPSERT_PATIENT } from './queries';
import { createPatientBody as upsertPatientBody, defaultValues, formatPatient, statesArray } from './utils';
import { FormLabel, FormContainer, LabelContainer, FormSection, FormRow, FormSubheader, CreateSuccessContainer, CreateSuccessCopy } from './styles';
import AddressModal from '../AddressModal';
import ConfirmChangesModal from '../ConfirmChangesModal';
import PendingChangesModal from '../PendingChangesModal';

const PatientForm = ({ onCreateSuccess, patient }: { onCreateSuccess?: () => void; patient?: Patient | null }) => {
  const history = useHistory();
  const [patientId, setPatientId] = useState('');
  const [createSuccess, setCreateSuccess] = useState<boolean | null>(null);
  const [isAddressModalVisible, setIsAddressModalVisible] = useState(false);
  const [isConfirmChangesModalVisible, setIsConfirmChangesModalVisible] = useState(false);
  const [isPendingChangesModalVisible, setIsPendingChangesModalVisible] = useState(false);
  const [locationPathname, setLocationPathname] = useState<string>();
  const [addressString, setAddressString] = useState<string | null>(null);
  const [addressError, setAddressError] = useState<string | null>(null);
  const [isUpdate, setIsUpdate] = useState(false);
  const pendingChangesStorageKey = 'pendingChangesConfirmed';
  const { handleSubmit, control, reset, register, watch, getValues, formState } = useForm<PatientInput>({
    mode: 'onBlur',
    defaultValues,
  });
  const emergencyContactName = watch('emergencyContactName');
  const emergencyContactLastName = watch('emergencyContactLastName');

  useEffect(() => {
    if (patient?.name) {
      const formattedPatient = formatPatient(patient);
      reset(formattedPatient);
      localStorage.setItem(pendingChangesStorageKey, 'false');
    }
  }, [patient, reset]);

  useEffect(() => {
    const handleBeforeUnload = (event: BeforeUnloadEvent) => {
      if (formState.isDirty) {
        event.preventDefault();
        event.returnValue = 'You have some unsaved changes to the patient data. Do you want to leave without saving?';
      }
    };
    window.addEventListener('beforeunload', handleBeforeUnload);
    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
    };
  });

  useEffect(() => {
    const unblock = history.block((location: Location) => {
      if (location.pathname === '/create-patient' || location.pathname.startsWith('/profile/')) {
        return true;
      }
      const noPendingChanges = !localStorage.getItem(pendingChangesStorageKey) || localStorage.getItem(pendingChangesStorageKey) === 'false';
      if (formState.isDirty && noPendingChanges) {
        setLocationPathname(location.pathname);
        setIsPendingChangesModalVisible(true);
        return false;
      }
      return true;
    });

    return () => {
      unblock();
    };
  }, [history, formState.isDirty]);

  const CanEditFields = (): boolean => {
    const { enablePatientProfileEditing } = useFlags();
    return !patient || enablePatientProfileEditing;
  };

  const [upsertPatient, { loading, error }] = useMutation(UPSERT_PATIENT, {
    onError: (err) => {
      console.error(err);
      if (err.message.includes("Invalid patient's home address")) {
        setAddressError(err.message);
        setIsAddressModalVisible(true);
      }
    },
    onCompleted: ({ upsertPatient }) => {
      setPatientId(upsertPatient.id);
      setCreateSuccess(true);
      reset(getValues());
      if (onCreateSuccess) {
        onCreateSuccess();
      }
    },
  });

  const onSubmit: SubmitHandler<PatientInput> = (data) => {
    setAddressString(`${data.addressLine1}${data.addressLine2 && `, ${data.addressLine2}`}; ${data.zip}; ${data.city} - ${data.state}`);
    setIsUpdate(!!data.id);
    data.updatedBy = localStorage.getItem('userEmail') ?? '';
    upsertPatient({
      variables: {
        patientInput: upsertPatientBody(data),
      },
    });
  };

  const onUpsertPatient = handleSubmit((data) => onSubmit(data));
  const onViewProfile = () => {
    if (isUpdate) {
      setCreateSuccess(null);
    } else {
      history.push(`/profile/${patientId}`);
    }
  };
  const onResetPage = () => {
    if (isUpdate) {
      history.push(`/create-patient`);
      return;
    }
    setCreateSuccess(null);
    setPatientId('');
    setIsUpdate(false);
    reset(defaultValues);
  };

  const handleContinueWithAddress = () => {
    const values = getValues();
    values.bypassAddressValidation = true;
    onSubmit(values);
  };

  const handleContinueWithPatientChanges = () => {
    const values = getValues();
    onSubmit(values);
  };

  const handlePendingChangesConfirm = () => {
    const targetUrl = locationPathname;
    localStorage.setItem(pendingChangesStorageKey, 'true');
    if (targetUrl) {
      history.push(targetUrl);
    }
  };

  const displayConfirmChangesModal = (evt?: React.MouseEvent<HTMLButtonElement>) => {
    evt && evt.preventDefault();
    setIsConfirmChangesModalVisible(true);
  };

  const getAddressModal = () => (
    <AddressModal
      isVisible={isAddressModalVisible}
      addressString={addressString}
      addressError={addressError}
      onClose={() => setIsAddressModalVisible(false)}
      onContinue={handleContinueWithAddress}
    />
  );

  const getConfirmChangesModal = () => (
    <ConfirmChangesModal isVisible={isConfirmChangesModalVisible} onClose={() => setIsConfirmChangesModalVisible(false)} onContinue={handleContinueWithPatientChanges} />
  );

  const getPendingChangesModal = () => (
    <PendingChangesModal isVisible={isPendingChangesModalVisible} onClose={() => setIsPendingChangesModalVisible(false)} onContinue={handlePendingChangesConfirm} />
  );

  if (createSuccess) {
    return (
      <CreateSuccessContainer>
        <img src={checkmark} height="45px" width="45px" data-testid="green-checkmark" />
        <CreateSuccessCopy data-testid="patientSuccessfullyCreated">{`Patient was successfully ${isUpdate ? 'updated' : 'created'}`}</CreateSuccessCopy>
        <Button
          onClick={onViewProfile}
          styles={{
            padding: '10px 46px',
            fontSize: '16px',
            lineHeight: '24px',
          }}
          dataTestId="viewProfileBtn"
        >
          View Patient Profile
        </Button>
        <Button
          secondary
          onClick={onResetPage}
          styles={{
            padding: '10px 46px',
            fontSize: '16px',
            lineHeight: '24px',
          }}
          dataTestId="createNewBtn"
        >
          Create New Patient
        </Button>
      </CreateSuccessContainer>
    );
  }

  return (
    <LoadingErrorWrapper loading={loading} error={error} styles={{ height: '100vh' }}>
      <LabelContainer>
        <FormLabel>PATIENT INFORMATION</FormLabel>
      </LabelContainer>
      <FormContainer onSubmit={onUpsertPatient} id="patientForm">
        <FormSection>
          <FormRow>
            <Input
              label="First Name*"
              labelFor="firstName"
              type="text"
              id="firstName"
              control={control}
              disabled={!CanEditFields()}
              rules={{
                required: {
                  value: true,
                  message: 'This field is required',
                },
              }}
            />
            <Input
              label="Last Name*"
              labelFor="lastName"
              type="text"
              id="lastName"
              control={control}
              disabled={!CanEditFields()}
              rules={{
                required: {
                  value: true,
                  message: 'This field is required',
                },
              }}
            />
            <Input
              label="Email*"
              labelFor="email"
              type="text"
              id="email"
              control={control}
              disabled={!!patient}
              rules={{
                required: {
                  value: true,
                  message: 'This field is required',
                },
              }}
            />
          </FormRow>
          <FormRow>
            <Input
              label="Phone Number*"
              labelFor="phone"
              type="text"
              id="phone"
              control={control}
              disabled={!CanEditFields()}
              phoneNumber
              rules={{
                required: {
                  value: true,
                  message: 'This field is required',
                },
                pattern: {
                  value: /^(\+0?1\s)?\(?\d{3}\)?[\s.-]?\d{3}[\s.-]?\d{4}$/i,
                  message: 'Invalid phone number',
                },
              }}
            />
            <Input
              label="Sex Assigned at Birth*"
              labelFor="gender"
              id="gender"
              control={control}
              disabled={!CanEditFields()}
              rules={{
                required: {
                  value: true,
                  message: 'This field is required',
                },
              }}
              selectOptions={[
                { label: 'Female', value: 'female' },
                { label: 'Male', value: 'male' },
                { label: 'Other', value: 'other' },
              ]}
            />
            <Input
              label="Date of Birth*"
              labelFor="dob"
              id="dob"
              type="date"
              control={control}
              disabled={!CanEditFields()}
              rules={{
                required: {
                  value: true,
                  message: 'This field is required',
                },
              }}
            />
          </FormRow>
        </FormSection>
        <FormSection>
          <FormSubheader>Address</FormSubheader>
          <FormRow>
            <Input
              label="Street Address*"
              labelFor="addressLine1"
              type="text"
              id="addressLine1"
              control={control}
              disabled={!CanEditFields()}
              rules={{
                required: {
                  value: true,
                  message: 'This field is required',
                },
              }}
            />
            <Input label="Apt Or Suite Number" labelFor="addressLine2" type="text" id="addressLine2" control={control} disabled={!CanEditFields()} />
            <Input
              label="City*"
              labelFor="city"
              type="text"
              id="city"
              control={control}
              disabled={!CanEditFields()}
              rules={{
                required: {
                  value: true,
                  message: 'This field is required',
                },
              }}
            />
            <Input
              label="State*"
              labelFor="state"
              type="text"
              id="state"
              control={control}
              disabled={!CanEditFields()}
              rules={{
                required: {
                  value: true,
                  message: 'This field is required',
                },
              }}
              selectOptions={statesArray}
            />
          </FormRow>
          <FormRow>
            <Input
              label="Zip Code*"
              labelFor="zip"
              type="text"
              id="zip"
              control={control}
              disabled={!CanEditFields()}
              rules={{
                required: {
                  value: true,
                  message: 'This field is required',
                },
              }}
            />
          </FormRow>
        </FormSection>
        <FormSection>
          <FormSubheader>Emergency Contact Information (Optional)</FormSubheader>
          <FormRow>
            <Input
              label="First Name"
              labelFor="emergencyContactName"
              type="text"
              id="emergencyContactName"
              control={control}
              disabled={!CanEditFields()}
              {...register('emergencyContactName', {
                required: {
                  value: !!emergencyContactLastName?.trim(),
                  message: 'This field is required',
                },
              })}
            />
            <Input
              label="Last Name"
              labelFor="emergencyContactLastName"
              type="text"
              id="emergencyContactLastName"
              control={control}
              disabled={!CanEditFields()}
              {...register('emergencyContactLastName', {
                required: {
                  value: !!emergencyContactName?.trim(),
                  message: 'This field is required',
                },
              })}
            />
            <Input
              label="Phone Number"
              labelFor="emergencyContactNumber"
              type="text"
              id="emergencyContactNumber"
              control={control}
              disabled={!CanEditFields()}
              phoneNumber
              rules={{
                pattern: {
                  value: /^(\+0?1\s)?\(?\d{3}\)?[\s.-]?\d{3}[\s.-]?\d{4}$/i,
                  message: 'Invalid phone number',
                },
              }}
            />
            <Input
              label="Relationship"
              labelFor="emergencyContactRelationship"
              id="emergencyContactRelationship"
              control={control}
              disabled={!CanEditFields()}
              selectOptions={[
                { label: 'Parent', value: 'parent' },
                { label: 'Child', value: 'child' },
                { label: 'Sibling', value: 'sibling' },
                { label: 'Grandparent', value: 'grandparent' },
                { label: 'Spouse', value: 'spouse' },
                { label: 'Partner', value: 'partner' },
                { label: 'Other', value: 'other' },
              ]}
            />
          </FormRow>
          {!patient && (
            <div style={{ width: 275, marginLeft: 15, marginTop: 30 }}>
              <Button onClick={onUpsertPatient} dataTestId="create-patient-button">
                Create Patient
              </Button>
            </div>
          )}
          {patient?.id && CanEditFields() && (
            <div style={{ width: 275, marginLeft: 15, marginTop: 30 }}>
              <Button onClick={displayConfirmChangesModal} dataTestId="create-patient-button" disabled={!formState.isDirty}>
                Update Patient
              </Button>
            </div>
          )}
        </FormSection>
        {getAddressModal()}
        {getConfirmChangesModal()}
        {getPendingChangesModal()}
      </FormContainer>
    </LoadingErrorWrapper>
  );
};

export default PatientForm;
