import React from 'react';
import { Control, Controller } from 'react-hook-form';
import { SearchPageProps } from 'types';
import StyledSearchBar, { ErrorText } from './styles';

const SearchBar = ({
  control,
  onSearch,
  emptySearch,
}: {
  control: Control<SearchPageProps>;
  onSearch: () => Promise<void>;
  emptySearch: boolean;
}) => {
  const onKeyPress = (e: React.KeyboardEvent<HTMLInputElement> | undefined) => {
    if (e?.key === 'Enter' && !e.shiftKey) {
      onSearch();
    }
  };

  const handleError = (error: any) => {
    if (error.type === 'manual') {
      return <ErrorText>{error.message}</ErrorText>;
    }

    return <ErrorText>Please search and/or select a category</ErrorText>;
  };

  return (
    <Controller
      control={control}
      name="searchTerm"
      rules={{
        required: emptySearch,
      }}
      render={({ field: { onChange, value }, fieldState: { error } }) => (
        <>
          {error ? handleError(error) : null}
          <StyledSearchBar
            onChange={onChange}
            error={error}
            value={value}
            onKeyPress={onKeyPress}
            data-testid="search-input"
            placeholder="Search by name, email, kit ID, tracking number, activation ID, etc"
          />
        </>
      )}
    />
  );
};

export default SearchBar;
