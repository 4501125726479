import { useState, useEffect } from 'react';
import { useForm, SubmitHandler } from 'react-hook-form';
import { useMutation, useQuery } from '@apollo/client';

import Button from 'components/Button';
import LoadingErrorWrapper from 'components/LoadingErrorWrapper';
import checkmark from 'assets/images/checkmark.png';

import TestTypeDropdown from './components/TestTypeDropdown';

import OrderTestContainer, {
  OrderTestHeader,
  OrderTestBody,
  OrderTestSubHeader,
  OrderTestCopy,
  OrderSuccessHeader,
  OrderSuccessCopy,
  OrderSuccessBody,
} from './styles';
import { GET_PLAN_DEFINITIONS, ORDER_TEST } from './queries';
import { PlanDefinition } from 'types';

const OrderTest = ({ patientId }: { patientId: string }) => {
  const [orderSuccess, setOrderSuccess] = useState<boolean | null>(null);
  const [planDefinitions, setPlanDefinitions] = useState<PlanDefinition[] | undefined>();
  const { control, handleSubmit } = useForm<{
    testType: { value: string; label: string };
  }>({
    mode: 'onBlur',
    defaultValues: {
      testType: { value: undefined, label: 'Select Test...' },
    },
  });

  const { loading: loadingPlans, error: errorPlans } = useQuery(GET_PLAN_DEFINITIONS, {
    fetchPolicy: 'network-only',
    onCompleted: ({ getPlanDefinitions }) => {
      setPlanDefinitions(getPlanDefinitions)},
    onError: (err) => console.error(err),
  });

  const [orderTest, { data, loading, error }] = useMutation(ORDER_TEST, {
    onError: (err) => console.error(err),
  });

  useEffect(() => {
    if (data?.orderTest) {
      setOrderSuccess(true);
    }
  }, [data]);

  const onSubmit: SubmitHandler<{
    testType: { value: string; label: string };
  }> = ({ testType: { value } }) => {
    orderTest({
      variables: {
        testParams: {
          testType: value,
          patientId,
        },
      },
    });
  };

  const onOrderTest = handleSubmit(onSubmit);

  if (error || errorPlans) {
    return (
      <OrderTestContainer>
        <OrderTestHeader>Order Test For Patient</OrderTestHeader>
        <OrderTestBody>
          <div>
            <OrderSuccessHeader>Something went wrong</OrderSuccessHeader>
            <OrderSuccessCopy>
              Please check that the patient and test are both valid
            </OrderSuccessCopy>
          </div>
        </OrderTestBody>
      </OrderTestContainer>
    );
  }

  return (
    <LoadingErrorWrapper
      loading={loading || loadingPlans}
      styles={{ width: '300px', height: '270px' }}
    >
      <OrderTestContainer>
        <OrderTestHeader>Order Test For Patient</OrderTestHeader>
        <OrderTestBody>
          {orderSuccess ? (
            <OrderSuccessBody>
              <img src={checkmark} className="checkmark" />
              <OrderSuccessHeader>Success! Order complete.</OrderSuccessHeader>
              <OrderSuccessCopy>
                View the patient profile to track the shipping status of the
                test.
              </OrderSuccessCopy>
            </OrderSuccessBody>
          ) : (
            <>
              <OrderTestSubHeader>Test Type</OrderTestSubHeader>
              <TestTypeDropdown control={control} planDefinitions={planDefinitions} />
              <OrderTestCopy>
                {
                  "Test will be sent to the address listed in this patient's profile"
                }
              </OrderTestCopy>
              <Button onClick={onOrderTest} dataTestId="submit-button">
                Order Test
              </Button>
            </>
          )}
        </OrderTestBody>
      </OrderTestContainer>
    </LoadingErrorWrapper>
  );
};

export default OrderTest;
