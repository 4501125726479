import ReactDOM from 'react-dom';
import { BrowserRouter as Router } from 'react-router-dom';
import { withLDProvider } from 'launchdarkly-react-client-sdk';

import App from './App';
import reportWebVitals from './reportWebVitals';
import Auth0ProviderWithHistory from '../src/components/Auth0';
import ApolloWrapper from 'components/ApolloWrapper';

const LDProvider = withLDProvider({
  clientSideID:
    window?.config?.REACT_APP_LAUNCHDARKLY_CLIENT_ID ??
    process.env.REACT_APP_LAUNCHDARKLY_CLIENT_ID,
  user: { key: (window?.config?.REACT_APP_PARTNER_NAME ?? process.env.REACT_APP_PARTNER_NAME) || '' }
})(App);

console.log(`partner_name: ${window?.config?.REACT_APP_PARTNER_NAME ?? process.env.REACT_APP_PARTNER_NAME}`);

ReactDOM.render(
  <Router>
    <Auth0ProviderWithHistory>
      <ApolloWrapper>
        <LDProvider>
          <App />
        </LDProvider>
      </ApolloWrapper>
    </Auth0ProviderWithHistory>
  </Router>,
  document.getElementById('root'),
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
