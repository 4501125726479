import { useState, useEffect } from 'react';
import { useForm, SubmitHandler } from 'react-hook-form';
import { useMutation } from '@apollo/client';
import { Location } from 'history';

import SearchResults from 'containers/SearchResults';
import LoadingErrorWrapper from 'components/LoadingErrorWrapper';
import Navigation from 'components/Navigation';

import SearchBar from './components/SearchBar';
import SearchDropdown from './components/SearchDropdown';

import {
  READ_CACHED_RESULTS,
  SEARCH_PATIENT,
  WRITE_CACHED_RESULTS,
} from './queries';
import { SearchBarContainer, SearchButton } from './styles';
import { Patient, SearchNavigationState, SearchPageProps } from 'types';
import { formatSearchParams } from './utils';

const SearchPage = ({ location }: { location: Location }) => {
  const [searchResponse, setSearchResults] = useState<Patient[] | null>(null);
  const state = location.state as SearchNavigationState;

  const { control, handleSubmit, watch, setError } = useForm<SearchPageProps>({
    mode: 'onSubmit',
    defaultValues: {
      searchTerm: '',
    },
  });
  const searchTerm = watch('searchTerm');
  const searchType = watch('searchType')?.value;
  const emptySearch = Boolean(!searchType && !searchTerm);

  const [
    searchPatient,
    { error: searchPatientError, loading: searchPatientLoading, client },
  ] = useMutation(SEARCH_PATIENT, {
    fetchPolicy: 'network-only',
    onCompleted: ({ searchPatient }) => {
      setSearchResults(searchPatient);
      client.writeQuery({
        query: WRITE_CACHED_RESULTS,
        data: { searchPatient },
      });
    },
    onError: (err) => console.error(err),
  });

  useEffect(() => {
    if (state?.getFromCache) {
      const data = client.readQuery({
        query: READ_CACHED_RESULTS,
      });

      if (data?.searchPatient) {
        setSearchResults(data.searchPatient);
      }
      window.history.replaceState({}, document.title);
    }
  }, [client, state]);

  const validateEmail = (email: String) => {
    return email.match(
      /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
    );
  };

  const onSubmit: SubmitHandler<SearchPageProps> = (data) => {
    const searchParams = formatSearchParams(data);

    if (validateEmail(data.searchTerm) === null && searchType === 'telecom') {
      return setError('searchTerm', {
        message: 'Please enter a valid email address',
        type: 'manual',
      });
    }

    searchPatient({
      variables: {
        searchParams,
      },
    });
  };

  const onSearch = handleSubmit(onSubmit);
  return (
    <Navigation
      page="search"
      render={() => (
        <>
          <SearchBarContainer>
            <div style={{ display: 'flex' }}>
              <SearchBar
                control={control}
                onSearch={onSearch}
                emptySearch={emptySearch}
              />
              <SearchDropdown
                control={control}
                emptySearch={emptySearch}
                searchTerm={searchTerm}
              />
              <SearchButton onClick={onSearch} data-testid="submit-button">
                Search
              </SearchButton>
            </div>
          </SearchBarContainer>
          <LoadingErrorWrapper
            loading={searchPatientLoading}
            styles={{ width: '100%', height: '100vh' }}
          >
            <SearchResults
              searchResults={searchResponse}
              searchPatientError={searchPatientError}
            />
          </LoadingErrorWrapper>
        </>
      )}
    />
  );
};

export default SearchPage;
