import { useState } from 'react';
import { useMutation } from '@apollo/client';
import { KitStatus as kitStatus, TestHistory, TrackingInfo } from 'types';
import format from 'date-fns/format';
import { useParams } from 'react-router-dom';
import { Fab } from '@material-ui/core';
import { GET_PDF_RESULT } from './queries';

import TestRowContainer, {
  KitStatus,
  RowColumn,
  ViewRejectedReason,
  ViewResultsButton,
  ViewQuestionIcon,
  CustomFab,
  ResultContainer,
  ItalicizedText,
  SemiboldText,
  MarginLeft,
} from './styles';

const reportStatuses = ['Fully Resulted By Lab', 'Partially Resulted By Lab'];

const TestRow = ({
  test: {
    activationDate,
    kitId,
    activationId,
    testType,
    kitStatus,
    trackingLab,
    trackingPatient,
    reportStatus,
    reportType,
  },
  getResultReport,
}: {
  test: TestHistory;
  // eslint-disable-next-line no-unused-vars
  getResultReport: (kitId: string, patientId: string) => void;
}) => {
  const NO_ACTIVATION_DATE_LABEL = 'Not activated yet';
  const NO_STATUS_TIMESTAMP_LABEL = 'Unknown date';
  const [isExpanded, setIsExpanded] = useState(false);

  const { patientId } = useParams<{ patientId: string }>();

  const getTrackingUrl = (trackingData: TrackingInfo) => {
    if (!trackingData) return;
    if (trackingData.carrier.toUpperCase() === 'UPS') {
      return `https://www.ups.com/track?loc=null&tracknum=${trackingData.trackingNumber}&requester=MB/trackdetails`;
    }
    if (trackingData.carrier.toUpperCase() === 'USPS') {
      return `https://tools.usps.com/go/TrackConfirmAction?tLabels=${trackingData.trackingNumber}`;
    }
    if (trackingData.carrier.toUpperCase() === 'FEDEX') {
      return `https://www.fedex.com/fedextrack/?tracknumbers=${trackingData.trackingNumber}&cntry_code=us`;
    }
  };

  const onGetReport = () => getResultReport(kitId, patientId);

  const [getPDFReport] = useMutation(GET_PDF_RESULT, {
    onCompleted: ({ getPDFResult: reportBuffer }) => {
      const currentDate = format(new Date(), 'MMM d, yyyy');
      const blob = new Blob([Buffer.from(reportBuffer, 'base64')], {
        type: 'application/zip',
      });
      const link = document.createElement('a');
      link.href = window.URL.createObjectURL(blob);
      const fileName = `Lab PDF Results - ${currentDate}.zip`;
      link.download = fileName;
      link.click();
    },
  });

  const labReportAvailable =
    reportType === 'qualitative' &&
    (
      reportStatus === 'final' ||
      reportStatus === 'amended' ||
      reportStatus === 'corrected'
    )
      ? true
      : false;

  const onGetPDF = async (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    e.stopPropagation();
    getPDFReport({
      variables: {
        kitId,
      },
    });
  };

  const getKitStatus = (status: string) =>
    status === 'Fully Resulted By Lab' ? 'Resulted' : status;

  const renderReasonIcon = (kitStatus: kitStatus) => {
    const rejectedReason = kitStatus?.rejectionReasons
      ? kitStatus?.rejectionReasons[kitStatus?.rejectionReasons.length - 1]
          .message
      : 'No reason';

    if (kitStatus?.status === 'Rejected By Lab') {
      return (
        <CustomFab>
          <Fab
            size="small"
            color="secondary"
            aria-label="add"
            style={{
              background: '#0149b0',
              transform: 'scale(0.7)',
              marginRight: '5px',
            }}
            data-testid="reason-icon"
          >
            <ViewQuestionIcon>?</ViewQuestionIcon>
          </Fab>
          <ViewRejectedReason data-testid="reason-message">
            {rejectedReason.toLocaleLowerCase()}
          </ViewRejectedReason>
        </CustomFab>
      );
    }

    return null;
  };

  const renderKitStatus = () => {
    if (kitStatus?.length === 1) {
      return (
        <KitStatus expanded={false} dropdown={false}>
          {getKitStatus(kitStatus[0].status)} -{' '}
          {kitStatus[0].timestamp ? (
            <ItalicizedText>
              {format(new Date(kitStatus[0].timestamp), 'MMMM d, y')}
            </ItalicizedText>
          ) : (
            NO_STATUS_TIMESTAMP_LABEL
          )}
          {renderReasonIcon(kitStatus[0])}
          <ResultContainer>
            {reportStatuses.includes(kitStatus?.[0].status) ||
            labReportAvailable ? (
              <SemiboldText>View: &nbsp;</SemiboldText>
            ) : null}
            {reportStatuses.includes(kitStatus?.[0].status) ? (
              <div>
                <ViewResultsButton onClick={onGetReport}>
                  imaware Results
                </ViewResultsButton>
              </div>
            ) : null}
            {reportStatuses.includes(kitStatus?.[0].status) &&
            labReportAvailable ? (
              <>&nbsp;{'|'}&nbsp;</>
            ) : null}
            {labReportAvailable ? (
              <>
                <ViewResultsButton onClick={onGetPDF}>
                  Lab Report
                </ViewResultsButton>
              </>
            ) : null}
          </ResultContainer>
        </KitStatus>
      );
    }
    if (kitStatus && !isExpanded) {
      return (
        <KitStatus expanded={isExpanded}>
          {getKitStatus(kitStatus[0].status)} -{' '}
          {kitStatus[0].timestamp ? (
            <ItalicizedText>
              {format(new Date(kitStatus[0].timestamp), 'MMMM d, y')}
            </ItalicizedText>
          ) : (
            NO_STATUS_TIMESTAMP_LABEL
          )}
          {renderReasonIcon(kitStatus[0])}
          <ResultContainer>
            {reportStatuses.includes(kitStatus?.[0].status) ? (
              <MarginLeft>
                <SemiboldText>View: &nbsp;</SemiboldText>
                <ViewResultsButton onClick={onGetReport}>
                  imaware Results
                </ViewResultsButton>
              </MarginLeft>
            ) : null}
            {reportStatuses.includes(kitStatus?.[0].status) &&
            labReportAvailable ? (
              <>&nbsp;{'|'}&nbsp;</>
            ) : null}
            {labReportAvailable ? (
              <>
                <ViewResultsButton onClick={onGetPDF}>
                  Lab Report
                </ViewResultsButton>
              </>
            ) : null}
          </ResultContainer>
        </KitStatus>
      );
    }
    return kitStatus?.map((status) => (
      <KitStatus expanded={isExpanded} key={status.timestamp}>
        {getKitStatus(status.status)} -{' '}
        {status.timestamp ? (
          <ItalicizedText>
            {format(new Date(status.timestamp), 'MMMM d, y')}
          </ItalicizedText>
        ) : (
          NO_STATUS_TIMESTAMP_LABEL
        )}
        {renderReasonIcon(status)}
        <ResultContainer>
          {reportStatuses.includes(status.status) ? (
            <MarginLeft>
              <SemiboldText>View: &nbsp;</SemiboldText>
              <ViewResultsButton onClick={onGetReport}>
                imaware Results
              </ViewResultsButton>
            </MarginLeft>
          ) : null}
          {reportStatuses.includes(status.status) &&
          labReportAvailable ? (
            <>&nbsp;{'|'}&nbsp;</>
          ) : null}
          {reportStatuses.includes(status.status) &&
          labReportAvailable ? (
            <>
              <ViewResultsButton onClick={onGetPDF}>
                Lab Report
              </ViewResultsButton>
            </>
          ) : null}
        </ResultContainer>
      </KitStatus>
    ));
  };

  const toggleIsExpanded = () => setIsExpanded((prevState) => !prevState);

  return (
    <TestRowContainer>
      <RowColumn data-testId={`row-${kitId}-test-type`}>{testType}</RowColumn>
      <RowColumn data-testId={`row-${kitId}-kit-value`}>{kitId}</RowColumn>
      <RowColumn data-testId={`row-${activationId}-activation-value`}>{activationId}</RowColumn>
      <RowColumn data-testId={`row-${kitId}-activation-date`}>
        {activationDate
          ? format(new Date(activationDate), 'MMMM d, y')
          : NO_ACTIVATION_DATE_LABEL}
      </RowColumn>
      <RowColumn
        data-testId={`row-${kitId}-kit-status`}
        onClick={toggleIsExpanded}
      >
        {renderKitStatus()}
      </RowColumn>
      <RowColumn data-testId={`row-${kitId}-patient-tracking`}>
        <a href={getTrackingUrl(trackingPatient)} target="#"
          style={{ wordBreak: 'break-all' }}
        >
          {trackingPatient?.trackingNumber ?? ''}
        </a>
      </RowColumn>
      <RowColumn data-testId={`row-${kitId}-lab-tracking`}>
        <a href={getTrackingUrl(trackingLab)} target="#"
          style={{ wordBreak: 'break-all' }}
        >
          {trackingLab?.trackingNumber ?? ''}
        </a>
      </RowColumn>
    </TestRowContainer>
  );
};

export default TestRow;
