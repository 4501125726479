import React from 'react';
import { HeaderColumn } from '../../containers/SearchResults/styles';

interface SortableColumnHeaderProps {
  column: string;
  activeSortColumn: string | undefined;
  sortDirection: string | undefined;
  onSort: Function;
}

const SortableColumnHeader: React.FC<SortableColumnHeaderProps> = ({ column, activeSortColumn, sortDirection, onSort }) => {
  const isActive = activeSortColumn === column;
  const isAscending = isActive && sortDirection === 'asc';

  const handleClick = () => {
    let newSortDirection = 'asc';

    if (isActive) {
      // If the clicked column is already active, toggle the sort direction
      newSortDirection = isAscending ? 'desc' : 'asc';
    }

    onSort(column, newSortDirection);
  };

  return (
    <HeaderColumn>
      <span onClick={handleClick} style={{cursor: 'pointer'}}>{column}</span>
      {isActive && <span onClick={handleClick} style={{cursor: 'pointer'}}>{isAscending ? ' ▲' : ' ▼'}</span>}
    </HeaderColumn>
  );
};

export default SortableColumnHeader;
