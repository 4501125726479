import styled from 'styled-components';
import searchBarIcon from 'assets/images/search-bar-icon.png';
import { FieldError } from 'react-hook-form';

interface SearchBarProps {
  error?: FieldError;
}

export default styled.input<SearchBarProps>`
  background: url(${searchBarIcon}) no-repeat;
  background-color: white;
  background-position-x: 14px;
  background-position-y: center;
  width: 420px;
  margin-right: 10px;
  font-size: 14px;
  max-height: 36px;
  font-family: Roboto;
  line-height: 24px;
  border: ${({ error }) =>
    error ? '2px solid #D80000' : '2px solid transparent'};
  border-radius: 4px 0 0 4px;
  padding-left: 45px;
  color: rgba(0, 0, 0, 0.7);
`;

export const ErrorText = styled.div`
  color: #d80000;
  margin-top: 10px;
  font-family: Lato;
  font-size: 12px;
  line-height: 24px;
  font-weight: bold;
  position: absolute;
  top: 79px;
`;
