import styled, { css } from 'styled-components';

interface StyleProps {
  width?: string;
  height?: string;
}

const GlobalStyles = css<StyleProps>`
  width: ${(props) => props.width};
  height: 65vh;
`;

export default styled.div<StyleProps>`
  ${GlobalStyles}
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 15px 0;
`;
