import styled from 'styled-components';

export const InputContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-right: 15px;
`;

export const InputLabel = styled.label`
  font-family: Lato;
  font-weight: 700;
  font-size: 12px;
  color: rgb(47, 47, 47);
  text-transform: uppercase;
  margin-bottom: 10px;
  margin-top: 10px;
  letter-spacing: 0.8px;
`;

export const StyledInput = styled.input`
  height: 45px;
  border: 1px solid #b9c8d3;
  border-radius: 4px;
  width: 260px;
  padding: 0 15px;
  box-sizing: border-box;
  font-family: Lato;
  font-size: 14px;
`;

export const StyledSelect = styled.select`
  height: 45px;
  border: 1px solid #b9c8d3;
  border-radius: 4px;
  width: 260px;
  padding: 0 15px;
  box-sizing: border-box;
  font-family: Lato;
  font-size: 14px;
`;
