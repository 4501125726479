export const options = [
  {
    value: 9999,
    label: 'All Time',
  },
  {
    value: 1,
    label: 'Last Day',
  },
  {
    value: 7,
    label: 'Last 7 Days',
  },
  {
    value: 30,
    label: 'Last 30 Days',
  },
  {
    value: 60,
    label: 'Last 60 Days',
  },
  {
    value: 90,
    label: 'Last 90 Days',
  },
  {
    value: 180,
    label: 'Last 180 Days',
  },
  {
    value: 365,
    label: 'Last 365 Days',
  },
];
