import { getStyles } from 'components/SharedDropdownComponents';
import Select from 'react-select';

import DropdownWrapper, { dropdownStyles } from './styles';
import { getInitialPartnerDropDownOption, getOptions } from './utils';

// eslint-disable-next-line no-unused-vars
const CompanyDropdown = ({ onChange }: { onChange: (event: any) => void }) => (
  <DropdownWrapper data-testid="company-dropdown">
    <Select
      options={getOptions()}
      onChange={onChange}
      value={getInitialPartnerDropDownOption(window.location.host)}
      styles={{
        ...getStyles(),
        ...dropdownStyles,
      }}
      isSearchable={false}
    />
  </DropdownWrapper>
);

export default CompanyDropdown;
