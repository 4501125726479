import { gql } from '@apollo/client';

const SEARCH_PATIENT_REPORT_FIELDS = `
  patient_id
  patient_name
  email
  test_type
  kit_id
  kit_status
  last_updated
`;

const SEARCH_PATIENT_REPORT_QUERY_PARAMS = `
  testType
  testStatus
  lastUpdated
  sortColumn
  sortDirection
`;

export const WRITE_CACHED_RESULTS = gql`
  query WriteSearchResults {
    searchPatientReport {
      ${SEARCH_PATIENT_REPORT_FIELDS}
    },
    searchParametersData {
      ${SEARCH_PATIENT_REPORT_QUERY_PARAMS}
    }
  }
`;

export const READ_CACHED_RESULTS = gql`
  query GetSearchResults {
    searchPatientReport {
      ${SEARCH_PATIENT_REPORT_FIELDS}
    },
    searchParametersData {
      ${SEARCH_PATIENT_REPORT_QUERY_PARAMS}
    }
  }
  `;

export const SEARCH_PATIENT_REPORT = gql`
  mutation SearchPatientReport($searchParams: SearchPatientReportParams!) {
    searchPatientReport(searchParams: $searchParams) {
       ${SEARCH_PATIENT_REPORT_FIELDS}
    }
  }
`;
