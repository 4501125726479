import InputMask from 'react-input-mask';

import { Control, Controller } from 'react-hook-form';
import {
  InputContainer,
  InputLabel,
  StyledInput,
  StyledSelect,
} from './styles';
import ErrorMessage from '../ErrorMessage';

interface InputProps {
  label: string;
  id: string;
  labelFor: string;
  control: Control<any, object>;
  type?: string;
  selectOptions?: Array<{
    value: string;
    label: string;
  }>;
  phoneNumber?: boolean;
  rules?: any;
  disabled?: boolean;
}

const Input = ({
  label,
  id,
  labelFor = '',
  control,
  type,
  selectOptions,
  phoneNumber,
  rules,
  disabled,
}: InputProps) => {
  return (
    <InputContainer>
      <InputLabel htmlFor={labelFor}>{label}</InputLabel>
      <Controller
        control={control}
        name={labelFor}
        rules={rules}
        render={({
          field: { onChange, onBlur, value },
          fieldState: { error },
        }) => (
          <>
            {selectOptions ? (
              <StyledSelect
                onChange={onChange}
                onBlur={onBlur}
                defaultValue=""
                value={value}
                disabled={disabled}
              >
                <option value="" data-testid={`selected-option-${value}`} disabled>
                  Select
                </option>
                {selectOptions.map(({ value, label }) => {
                  return (
                    <option value={value} key={value}>
                      {label}
                    </option>
                  );
                })}
              </StyledSelect>
            ) : phoneNumber ? (
              <InputMask
                mask="9999999999"
                maskPlaceholder={null}
                onChange={onChange}
                onBlur={onBlur}
                value={value}
                disabled={disabled}
              >
                <StyledInput
                  type={type}
                  id={id}
                  name={id}
                  onChange={onChange}
                  onBlur={onBlur}
                  value={value}
                />
              </InputMask>
            ) : (
              <StyledInput
                type={type}
                id={id}
                name={id}
                onChange={onChange}
                onBlur={onBlur}
                value={value}
                disabled={disabled}
              />
            )}
            {error && <ErrorMessage errorMessage={error.message} />}
          </>
        )}
      />
    </InputContainer>
  );
};

export default Input;
