import { Patient } from 'types';

export const formatPatient = (row: Patient) => {
  const { id, name = [], telecom } = row;

  const { family, given } = name[0];
  const email = telecom.find((t) => t.system === 'email')?.value ?? '';

  return {
    id,
    name: `${given[0]} ${family}`,
    email,
  };
};


export const formatDate = (dateString: string | undefined) => {
  const testDate = dateString?.match(/\d{4}(-\d{2}){2}/);
  const formattedDate = testDate ? testDate[0].replace(/-/g, '/') : null;
  return formattedDate;
};
