import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useAuth0 } from '@auth0/auth0-react';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { useLazyQuery } from '@apollo/client';

import HeaderContainer, { ContentContainer, LogoutButton, MainContainer, SideNav, SideNavElement } from './styles';
import CompanyDropdown from './components/CompanyDropdown';
import DownloadCeliacKitsBtn from './components/DownloadCeliacKitsBtn';
import { GET_CELIAC_KITS_REPORT } from './components/DownloadCeliacKitsBtn/queries';
import DownloadStiReportBtn from './components/DownloadStiReportBtn';
import { GET_STI_REPORT } from './components/DownloadStiReportBtn/queries';

import { Alert, Snackbar } from '@mui/material';
import { downloadCsvFile } from 'utilities/csv';

const Navigation = ({ render, page }: { render: () => React.ReactElement; page: string }) => {
  const history = useHistory();
  const { logout } = useAuth0();
  const { enablePatientReports } = useFlags();
  const isImawareEmployee = localStorage.getItem('isImawareEmployee') === 'true';
  const [errorLoadingCeliacReport, setErrorLoadingCeliacReport] = useState<boolean>(false);
  const [errorLoadingStiReport, setErrorLoadingStiReport] = useState<boolean>(false);
  
  const onSearch = () => history.push('/');
  const onSearchReports = () => history.push('/reports');
  const onCreatePatient = () => history.push('/create-patient');
  const onLogout = () => logout({ returnTo: window.location.origin });
  const onChange = (event: { value: string; label: string }) => {
    document.location = event.value;
  };
  const [getCeliacReport, { loading: loadingCeliacReport }] = useLazyQuery(GET_CELIAC_KITS_REPORT, {
    fetchPolicy: 'network-only',
    nextFetchPolicy: 'network-only',
    onError: (err) => {
      console.log(err);
      setErrorLoadingCeliacReport(true);
    },
    onCompleted: ({getCeliacReport}) => {
      const csvFieldNames = [{
        label: 'SampleId',
        valuePath: 'kitId',
      },
      {
        label: 'CollectionTime',
        valuePath: 'activationTime',
      },
      {
        label: 'ActivationId',
        valuePath: 'activationId',
      }]
      downloadCsvFile(getCeliacReport, csvFieldNames);
    }
  });

  const [getStiReport, { loading: loadingStiReport }] = useLazyQuery(GET_STI_REPORT, {
    fetchPolicy: 'network-only',
    nextFetchPolicy: 'network-only',
    onError: (err) => {
      console.log(err);
      setErrorLoadingStiReport(true);
    },
    onCompleted: ({getStiReport}) => {
      const csvFieldNames = [{
        label: 'KitId',
        valuePath: 'kitId',
      },
      {
        label: 'PatientFirstName',
        valuePath: 'patientFirstName',
      },
      {
        label: 'PatientLastName',
        valuePath: 'patientLastName',
      },
      {
        label: 'PatientEmail',
        valuePath: 'patientEmail',
      },
      {
        label: 'PatientState',
        valuePath: 'patientState',
      },
      {
        label: 'TestType',
        valuePath: 'testType',
      },
      {
        label: 'TestStatus',
        valuePath: 'testStatus',
      },
      {
        label: 'TestInterpretation',
        valuePath: 'testInterpretation',
      },
      {
        label: 'PositiveBiomarkers',
        valuePath: 'positiveBiomarkers',
      },
      {
        label: 'ResultedTimestamp',
        valuePath: 'resultedTimestamp',
      },
    
    ]
      downloadCsvFile(getStiReport, csvFieldNames);
    }
  });

  const onDownloadCeliacKits = () => {
    getCeliacReport({fetchPolicy: 'network-only'});
  };

  const onDownloadStiReport = () => {
    getStiReport({fetchPolicy: 'network-only'});
  };

  const isImawareCompany = () => {
    if (document.location.hostname.includes("admin.imaware")){
      return true;
    }
    return false;
  }

  return (
    <>
      <HeaderContainer isImawareEmployee={isImawareEmployee}>
        {isImawareEmployee && (
          <div style={{display: 'flex'}}>
            <CompanyDropdown onChange={onChange} />
            {isImawareCompany() && (<DownloadCeliacKitsBtn loading={loadingCeliacReport} onDownload={onDownloadCeliacKits} />)}
            {isImawareCompany() && (<DownloadStiReportBtn loading={loadingStiReport} onDownload={onDownloadStiReport} />)}
          </div>
        )}
      </HeaderContainer>
      <MainContainer>
        <SideNav>
          <div>
            <SideNavElement active={page === 'search'} icon="search" onClick={onSearch}>
              Search
            </SideNavElement>
            {enablePatientReports ? (
              <SideNavElement active={page === 'reports'} icon="reports" onClick={onSearchReports}>
                Reports
              </SideNavElement>
            ) : null}
            <SideNavElement active={page === 'create'} icon="create" onClick={onCreatePatient}>
              Create New Patient
            </SideNavElement>
          </div>
          <LogoutButton onClick={onLogout}>Logout</LogoutButton>
        </SideNav>
        <ContentContainer>{render()}</ContentContainer>
      </MainContainer>
      <Snackbar anchorOrigin={{ vertical: 'top', horizontal: 'center' }} open={errorLoadingCeliacReport || errorLoadingStiReport} onClose={() => { setErrorLoadingStiReport(false); setErrorLoadingCeliacReport(false)}} autoHideDuration={6000}>
        <Alert severity="error">Error downloading the report. Please try again.</Alert>
      </Snackbar>
    </>
  );
};

export default Navigation;
