import gql from 'graphql-tag';

export const GET_PATIENT = gql`
  query GetPatient($id: String!) {
    getPatient(id: $id) {
      id
      name {
        family
        given
      }
      telecom {
        system
        value
      }
      address {
        city
        state
        postalCode
        country
        line
      }
      gender
      birthDate
      contact {
        name {
          family
          given
        }
        relationship {
          coding {
            display
            userSelected
          }
          text
        }
        telecom {
          system
          value
        }
      }
      testHistory {
        testType
        kitId
        activationId
        activationDate
        trackingPatient {
          carrier
          trackingNumber
        }
        trackingLab {
          carrier
          trackingNumber
        }
        kitStatus {
          status
          timestamp
          rejectionReasons {
            code
            message
          }
        }
        reportType
        reportStatus
      }
    }
  }
`;

export const GET_RESULT_REPORT = gql`
  mutation GetResultReport($kitId: String!, $patientId: String!) {
    getResultReport(kitId: $kitId, patientId: $patientId)
  }
`;
