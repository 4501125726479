import React from 'react';
import { components, ValueContainerProps, OptionProps, CSSObjectWithLabel } from 'react-select';

import { FieldError } from 'react-hook-form';
import { SelectableItem } from '../../types';

export const Option = (props: OptionProps<any, any>) => (
  <div>
    <components.Option {...props}>
      <input type={props.selectProps.placeholder === 'Last Updated' ? 'radio' : 'checkbox'} checked={props.isSelected} onChange={() => null} /> <label>{props.label}</label>
    </components.Option>
  </div>
);

export const MultiValueLabel = () => null;
export const MultiValueRemove = () => null;
export const ClearIndicator = () => null;

export const renderContent = (value: SelectableItem[] | null, placeholder: React.ReactNode, children: React.ReactNode) => {
  const childArray = React.Children.toArray(children);
  const lastChild = childArray[childArray.length - 1];
  let selectedType = placeholder === 'Test Type' ? 'Tests' : 'Statuses';
  const checkValue = value?.length === 1 ? value[0].label : `${calculateSelectedItemsLength(value)} ${selectedType} ${'Selected'}`;
  if (value === null || !value.length) {
    return childArray;
  }
  if (value.length >= 0) {
    return (
      <>
        {checkValue}
        {lastChild}
      </>
    );
  }
};

const calculateSelectedItemsLength = (items: SelectableItem[] | null) => {
  if (!items?.length) {
    return 0;
  }
  return items.filter((i) => i.label !== 'Select All').length;
};

export const ValueContainer = ({ children, ...props }: ValueContainerProps<any, any>) => {
  const { selectProps } = props;
  const { value, placeholder } = selectProps;
  return <components.ValueContainer {...props}>{renderContent(value, placeholder, children)}</components.ValueContainer>;
};

export const selectStyles = {
  control: (provided: CSSObjectWithLabel) => ({
    ...provided,
    border: '2px solid transparent',
    height: '42px',
    borderRadius: '0px 4px 4px 0',
    minWidth: '200px',
  }),
  menu: (provided: CSSObjectWithLabel) => ({
    ...provided,
    maxHeight: '370px',
    overflow: 'auto',
  }),
  valueContainer: (provided: CSSObjectWithLabel) => ({
    ...provided,
    fontFamily: 'Roboto',
    fontSize: '14px',
  }),
  indicatorSeparator: (provided: CSSObjectWithLabel) => ({
    ...provided,
    display: 'none',
  }),
  indicatorsContainer: (provided: CSSObjectWithLabel) => ({
    ...provided,
    marginRight: '7px',
  }),
  placeholder: (provided: CSSObjectWithLabel) => ({
    ...provided,
    fontFamily: 'Roboto',
    fontSize: '14px',
    color: 'rgba(0, 0, 0, 0.7)',
  }),
  singleValue: (provided: CSSObjectWithLabel) => ({
    ...provided,
    fontFamily: 'Roboto',
    fontSize: '14px',
    lineHeight: '24px',
    color: 'rgba(0, 0, 0, 0.7)',
  }),
  multiValue: (provided: CSSObjectWithLabel) => ({
    ...provided,
    background: 'none',
    marginBottom: '20px',
  }),
  menuList: (provided: CSSObjectWithLabel) => ({
    ...provided,
    fontFamily: 'Roboto',
    fontSize: '14px',
    lineHeight: '24px',
    color: 'rgba(0, 0, 0, 0.7)',
  }),
  option: (provided: CSSObjectWithLabel) => ({
    ...provided,
    backgroundColor: 'white',
    fontFamily: 'Roboto',
    color: 'rgba(0, 0, 0, 0.7)',
    ':active': {
      backgroundColor: 'white',
      background: 'white',
    },
  }),
};

export const getStyles = (error?: FieldError | boolean) => ({
  ...selectStyles,
  ...(error && {
    control: (provided: CSSObjectWithLabel) => ({
      ...provided,
      border: '2px solid #D80000',
      height: '42px',
      borderRadius: '0px 4px 4px 0',
    }),
  }),
});
