import styled from 'styled-components';
import alert from 'assets/images/alert.png';

export default styled.div`
  display: flex;
  flex-direction: column;
  width: 300px;
`;

export const OrderTestHeader = styled.div`
  background: #f7f9fb;
  text-transform: uppercase;
  font-family: Lato;
  font-weight: bold;
  font-size: 14px;
  line-height: 17px;
  letter-spacing: 0.2px;
  color: #59748a;
  padding: 17px 22px;
  border-radius: 4px 4px 0 0;
`;

export const OrderTestSubHeader = styled.h4`
  font-family: Lato;
  font-style: normal;
  font-weight: bold;
  font-size: 12px;
  line-height: 24px;
  letter-spacing: 0.8px;
  text-transform: uppercase;
  margin: 15px 0 0 0;
`;

export const OrderTestBody = styled.div`
  display: flex;
  flex-direction: column;
  background: white;
  padding: 0 20px 20px 20px;
  border-radius: 0 0 4px 4px;
  img.checkmark {
    margin-top: 37px;
  }
`;

export const OrderTestCopy = styled.h6`
  background: url(${alert}) no-repeat;
  padding-left: 20px;
  font-family: Lato;
  font-size: 12px;
  line-height: 16px;
  letter-spacing: 0.415385px;
  margin: 0 0 16px 0;
`;

export const OrderSuccessBody = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-bottom: 44px;
`;

export const OrderSuccessHeader = styled.h4`
  font-family: Lato;
  font-weight: bold;
  font-size: 18px;
  line-height: 22px;
  text-align: center;
  margin-top: 12px;
  margin-bottom: 5px;
`;

export const OrderSuccessCopy = styled.h6`
  font-family: Lato;
  font-size: 14px;
  line-height: 22px;
  text-align: center;
  margin: 0;
`;
