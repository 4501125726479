import styled from 'styled-components';

interface HeaderColumnProps {
  includesTracking?: boolean;
}

export default styled.div`
  background: white;
  height: calc(100% - 140px);
  margin-left: 24px;
  margin-right: 60px;
  display: flex;
  flex-direction: column;
  align-items: center;
  border-radius: 4px;
  margin-bottom: 55px;
  img {
    margin-top: 245px;
    height: 75px;
    width: 75px;
  }
`;

export const ResultRowContainer = styled.div`
  overflow-y: auto;
  width: 100%;
  height: 95%;
`;

export const ResultHeaderText = styled.h2`
  font-family: Lato;
  font-size: 20px;
  line-height: 32px;
  margin-left: 24px;
  margin-bottom: 8px;
`;

export const SearchHeader = styled.h2`
  font-family: Lora;
  font-weight: bold;
  font-size: 26px;
  line-height: 32px;
  text-align: center;
  margin-top: 15px;
  margin-bottom: 0;
`;

export const SearchCopy = styled.h4`
  font-family: Lato;
  font-size: 16px;
  line-height: 23px;
  text-align: center;
  margin-top: 15px;
`;

export const ResultsHeader = styled.div`
  display: flex;
  width: 100%;
  background: white;
  font-family: Lato;
  font-weight: bold;
  font-size: 12px;
  line-height: 14px;
  letter-spacing: 0.2px;
  text-transform: uppercase;
  color: #59748a;
  min-height: 60px;
  align-items: center;
  position: sticky;
  top: 0;
`;

export const HeaderColumn = styled.div<HeaderColumnProps>`
  flex-basis: ${({ includesTracking }) => (includesTracking ? '11%' : '20%')};
  flex: 1;
  :first-of-type {
    margin-left: 48px;
  }
`;

export const ResultBanner = styled.div`
  background: #fec2c2;
  border-radius: 4px;
  height: 35px;
  color: #d80000;
  font-family: 'Roboto';
  font-weight: 700;
  font-size: 12px;
  line-height: 14px;
  display: flex;
  margin-left: 24px;
  margin-right: 60px;
  justify-content: center;
  align-items: center;
`;

export const NoResultsBanner = styled.div`
  background: #ffedac;
  border: 1px solid #ff5c00;
  border-radius: 4px;
  height: 35px;
  color: #d80000;
  font-family: 'Roboto';
  font-size: 12px;
  line-height: 14px;
  display: flex;
  margin-left: 24px;
  margin-right: 60px;
  justify-content: center;
  align-items: center;
`;

export const ResultsBox = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`;
