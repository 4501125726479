import { FormattedResultRow } from 'types';
import ResultRowContainer, { RowColumn, TimeStamp } from './styles';
import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';

const ResultRow = ({ date, result, fromReports }: { date?: string | null; result: FormattedResultRow; fromReports: boolean }) => {
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => {
      setScreenWidth(window.innerWidth);
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const largeScreenStyles = {
    display: screenWidth >= 1300 ? 'block' : 'none',
    background: '#f7f9fb',
    borderRadius: '100px',
    fontFamily: 'Lato',
    fontSize: '11px',
    lineHeight: '24px',
    color: '#000000',
    border: '1px solid #000000',
    width: '100%',
    maxWidth: '182px',
    height: '25px',
    cursor: 'pointer',
    textDecoration: 'none',
    'text-align': 'center',
  };

  const smallScreenStyles = {
    display: screenWidth < 1300 ? 'block' : 'none',
    color: '#000000',
    textDecoration: 'underline',
    cursor: 'pointer',
    fontSize: '11px',
  };

  return (
    <ResultRowContainer style={{ height: 'auto' }}>
      <RowColumn>{result.name}</RowColumn>
      <RowColumn>{result.email}</RowColumn>
      <RowColumn>{result.testType}</RowColumn>
      <RowColumn>{result.kitId}</RowColumn>
      <RowColumn style={{ padding: '5px' }}>
        {result.kitStatus?.[0].status}
        {date ? <TimeStamp>Last updated on {date}</TimeStamp> : null}
      </RowColumn>
      <RowColumn>
        <Link style={largeScreenStyles} to={{ pathname: `/profile/${result.id}`, search: `?fromReports=${fromReports}` }}>
          View Patient Profile
        </Link>
        <Link style={smallScreenStyles} to={{ pathname: `/profile/${result.id}`, search: `?fromReports=${fromReports}` }}>
          Patient Profile
        </Link>
      </RowColumn>
    </ResultRowContainer>
  );
};

export default ResultRow;
