import styled from 'styled-components';
import toggleArrowExpanded from 'assets/images/toggle-arrow-expanded.png';
import toggleArrowCollapsed from 'assets/images/toggle-arrow-collapsed.png';

interface KitStatusProps {
  expanded: boolean;
  dropdown?: boolean;
}
export default styled.div`
  background: #f7f9fb;
  border-radius: 4px;
  display: flex;
  width: 100%;
  min-height: 49px;
  align-items: center;
  :nth-of-type(odd) {
    background: #ecf1f5;
  }
`;

export const RowColumn = styled.div`
  font-family: Lato;
  font-size: 14px;
  line-height: 17px;
  letter-spacing: 0.01em;
  color: #4c5862;
  padding: 0px 5px;
  flex-basis: 15%;
  a {
    color: #4c5862;
  }
  :first-of-type {
    margin-left: 22px;
    flex-basis: 18%;
  }
  :nth-of-type(4) {
    flex-basis: 22%;
    :hover {
      cursor: pointer;
    }
  }
`;

export const KitStatus = styled.div<KitStatusProps>`
  padding: 10px 0;
  margin-left: 13px;
  :first-of-type {
    margin-left: 0;
  }
  :first-of-type:before {
    ${({ dropdown }) => (dropdown === false ? null : "content: ''")};
    background: ${({ expanded }) =>
      expanded
        ? `url(${toggleArrowExpanded}) no-repeat`
        : `url(${toggleArrowCollapsed}) no-repeat`};
    background-position-y: 5px;
    padding-left: 13px;
    transform: rotate(180deg);
  }
`;

export const ViewResultsButton = styled.div`
  text-decoration: underline;
  display: inline;
`;

export const ViewRejectedReason = styled.div`
  position: absolute;
  background: white;
  color: black;
  border-radius: 5px;
  padding: 10px;
  width: auto;
  overflow: none;
  max-width: 250px;
  border: 1px solid #0000002e;
  display: flex;
  align-items: center;
  justify-content: center;
  text-transform: capitalize;
  display: none;
  z-index: 999;
`;

export const ViewQuestionIcon = styled.div`
  color: white;
  font-size: 18px;
`;

export const ContainerFab = styled.div`
  display: flex;
  flex-direction: row;
  background: red;
`;

export const CustomFab = styled.div`
  position: relative;
  display: inline;
  :hover ${ViewRejectedReason} {
    display: flex;
  }
`;

export const ResultContainer = styled.div`
  display: flex;
  flex-direction: row;
`;

export const ItalicizedText = styled.span`
  font-style: italic;
`;

export const SemiboldText = styled.span`
  font-weight: 600;
`;

export const MarginLeft = styled.div`
  margin-left: 14px;
`;
