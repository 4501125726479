import styled from 'styled-components';

export const PatientTableHeader = styled.h3`
  font-family: Lato;
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  margin-left: 24px;
  margin-bottom: 10px;
`;

export default styled.div`
  background: white;
  margin-left: 24px;
  margin-right: 46px;
  display: flex;
  flex-direction: column;
  align-items: center;
  border-radius: 4px;
  margin-bottom: 55px;
`;

export const HeaderColumn = styled.div`
  flex-basis: 15%;
  :first-of-type {
    margin-left: 22px;
    flex-basis: 18%;
  }
  :nth-of-type(4) {
    flex-basis: 22%;
  }
`;

export const ResultsHeader = styled.div`
  display: flex;
  width: 100%;
  background: white;
  font-family: Lato;
  font-weight: bold;
  font-size: 12px;
  line-height: 14px;
  letter-spacing: 0.2px;
  text-transform: uppercase;
  color: #59748a;
  min-height: 60px;
  align-items: center;
`;

export const NoTestContainer = styled.div`
  font-family: Lato;
  font-weight: bold;
  font-size: 18px;
  line-height: 14px;
  padding: 40px;
`;
