import { useState } from 'react';
import { useForm, SubmitHandler } from 'react-hook-form';
import { useMutation } from '@apollo/client';

import Button from 'components/Button';
import LoadingErrorWrapper from 'components/LoadingErrorWrapper';
import checkmark from 'assets/images/checkmark.png';

import SearchInput from './components/SearchInput';
import ActivateKitContainer, {
  ActivateKitBody,
  ActivateKitHeader,
  ActivateKitSubHeader,
  ActivateSuccessBody,
  ActivateSuccessCopy,
  ActivateSuccessHeader,
} from './styles';
import { ACTIVATE_KIT } from './queries';
import { activationIdNormalization } from '../../utils';

const ActivateKit = ({ patientId }: { patientId: string }) => {
  const [activateSuccess, setActivateSuccess] = useState<boolean | null>(null);
  const {
    control,
    handleSubmit,
    formState: {
      errors: { kitId: kitIdError },
    },
  } = useForm<{ kitId: string }>({
    mode: 'onBlur',
    defaultValues: { kitId: '' },
  });
  const [activateKit, { loading, error }] = useMutation(ACTIVATE_KIT, {
    onError: (err) => console.error(err),
    onCompleted: () => setActivateSuccess(true),
  });

  const onSubmit: SubmitHandler<{ kitId: string }> = ({
    kitId,
  }: {
    kitId: string;
  }) => {
    activateKit({
      variables: {
        kitId: activationIdNormalization(kitId),
        patientId,
      },
    });
  };

  const onActivateKit = handleSubmit(onSubmit);

  if (error) {
    return (
      <ActivateKitContainer>
        <ActivateKitHeader>Activate Kit For Patient</ActivateKitHeader>
        <ActivateKitBody>
          <div>
            <ActivateSuccessHeader>Something went wrong</ActivateSuccessHeader>
            <ActivateSuccessCopy>
              Please check that the Kit ID / Activation ID is valid
            </ActivateSuccessCopy>
          </div>
        </ActivateKitBody>
      </ActivateKitContainer>
    );
  }

  return (
    <LoadingErrorWrapper
      loading={loading}
      styles={{ width: '300px', height: '270px' }}
    >
      <ActivateKitContainer>
        <ActivateKitHeader>Activate Kit for Patient</ActivateKitHeader>
        <ActivateKitBody error={kitIdError}>
          {activateSuccess ? (
            <ActivateSuccessBody>
              <img src={checkmark} className="checkmark" data-testid="green-checkmark"/>
              <ActivateSuccessHeader data-testid="success-activation-text">
                Success! Kit Activated.
              </ActivateSuccessHeader>
            </ActivateSuccessBody>
          ) : (
            <>
              <ActivateKitSubHeader>Kit ID / Activation ID</ActivateKitSubHeader>
              <SearchInput control={control} />
              <Button onClick={onActivateKit} dataTestId="submit-button">
                Activate Kit
              </Button>
            </>
          )}
        </ActivateKitBody>
      </ActivateKitContainer>
    </LoadingErrorWrapper>
  );
};

export default ActivateKit;
