import { Control, Controller } from 'react-hook-form';
import Select, { ActionMeta, MultiValue } from 'react-select';

import { Option, MultiValueLabel, MultiValueRemove, ClearIndicator, ValueContainer, getStyles } from 'components/SharedDropdownComponents';

import DropdownContainer from './styles';
import { options } from './utils';
import { OptionType, OptionsType, ReportsPageProps } from 'types';

const TestStatusDropdown = ({ control }: { control: Control<ReportsPageProps> }) => {
  // eslint-disable-next-line no-unused-vars
  const handleOnChange = (
    value: MultiValue<OptionType>,
    action: ActionMeta<any>,
    // eslint-disable-next-line no-unused-vars
    onChange: (event: OptionsType) => void,
  ) => {
    if (action.action === 'select-option' && action.option.value === 'Select All') {
      onChange(options);
    } else if (action.action === 'deselect-option' && action.option.value === 'Select All') {
      onChange([]);
    } else onChange(Array.from(value.values()));
  };

  const optionsToSelect = options.map((o) => ({ label: o.label, value: o.value }));

  return (
    <DropdownContainer>
      <Controller
        control={control}
        name="testStatus"
        rules={{ required: true }}
        render={({ field: { onChange, onBlur, value }, fieldState: { error } }) => (
          <Select
            isMulti
            options={optionsToSelect}
            onChange={(e, action) => handleOnChange(e, action, onChange)}
            onBlur={onBlur}
            value={value}
            menuPlacement="auto"
            menuPosition="absolute"
            placeholder="Status"
            components={{
              MultiValueLabel,
              MultiValueRemove,
              ValueContainer,
              ClearIndicator,
              Option,
            }}
            styles={getStyles(error)}
            hideSelectedOptions={false}
            closeMenuOnSelect={false}
          />
        )}
      />
    </DropdownContainer>
  );
};

export default TestStatusDropdown;
