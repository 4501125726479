import { SearchPageProps } from 'types';

export const formatSearchParams = (data: SearchPageProps) => {
  const {
    searchTerm,
    searchType: searchTypeRaw = { value: '' },
  } = data;
  const { value: searchType } = searchTypeRaw;
  return { searchTerm, searchType };
};
