import { Control, Controller } from 'react-hook-form';
import SearchInputContainer, { SearchInputError } from './styles';

const SearchInput = ({ control }: { control: Control<{ kitId: string }> }) => {
  return (
    <Controller
      control={control}
      name="kitId"
      rules={{
        required: {
          value: true,
          message: 'Please enter a valid code',
        },
      }}
      render={({
        field: { onChange, onBlur, value },
        fieldState: { error },
      }) => (
        <>
          <SearchInputContainer
            type="text"
            placeholder="Enter Kit ID / Activation ID"
            value={value}
            onChange={onChange}
            onBlur={onBlur}
            error={error}
            data-testid="kit-id-input"
          />
          {error && <SearchInputError>{error.message}</SearchInputError>}
        </>
      )}
    />
  );
};

export default SearchInput;
