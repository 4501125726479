import { ReportsPageProps } from 'types';

const ReportColumnMap = new Map<string, string>([
  ['Kit Status', 'last_updated'],
  ['Kit ID', 'kit_id'],
  ['Test Type', 'test_type'],
  ['Patient Name', 'patient_name'],
  ['Email', 'email'],
]);

export const formatSearchParams = (data: ReportsPageProps) => {
  const { testStatus: testStatusRaw, testType: testTypeRaw, lastUpdated: lastUpdatedRaw, sortColumn, sortDirection } = data;
  let days;
  let testTypes;
  const status = testStatusRaw.map((type) => type.value).filter((type) => type !== 'Select All');
  if (testTypeRaw) {
    testTypes = testTypeRaw.map((type) => type.value).filter((type) => type !== 'Select All');
  }
  if (lastUpdatedRaw) {
    days = lastUpdatedRaw.value;
  }
  return { status, days, testTypes, sortColumn: ReportColumnMap.get(sortColumn), sortDirection };
};
