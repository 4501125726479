import { Patient } from 'types';

export const statesArray = [
  {
    label: 'Alabama',
    value: 'AL',
  },
  {
    label: 'Alaska',
    value: 'AK',
  },
  {
    label: 'American Samoa',
    value: 'AS',
  },
  {
    label: 'Arizona',
    value: 'AZ',
  },
  {
    label: 'Arkansas',
    value: 'AR',
  },
  {
    label: 'California',
    value: 'CA',
  },
  {
    label: 'Colorado',
    value: 'CO',
  },
  {
    label: 'Connecticut',
    value: 'CT',
  },
  {
    label: 'Delaware',
    value: 'DE',
  },
  {
    label: 'District Of Columbia',
    value: 'DC',
  },
  {
    label: 'Federated States Of Micronesia',
    value: 'FM',
  },
  {
    label: 'Florida',
    value: 'FL',
  },
  {
    label: 'Georgia',
    value: 'GA',
  },
  {
    label: 'Guam',
    value: 'GU',
  },
  {
    label: 'Hawaii',
    value: 'HI',
  },
  {
    label: 'Idaho',
    value: 'ID',
  },
  {
    label: 'Illinois',
    value: 'IL',
  },
  {
    label: 'Indiana',
    value: 'IN',
  },
  {
    label: 'Iowa',
    value: 'IA',
  },
  {
    label: 'Kansas',
    value: 'KS',
  },
  {
    label: 'Kentucky',
    value: 'KY',
  },
  {
    label: 'Louisiana',
    value: 'LA',
  },
  {
    label: 'Maine',
    value: 'ME',
  },
  {
    label: 'Marshall Islands',
    value: 'MH',
  },
  {
    label: 'Maryland',
    value: 'MD',
  },
  {
    label: 'Massachusetts',
    value: 'MA',
  },
  {
    label: 'Michigan',
    value: 'MI',
  },
  {
    label: 'Minnesota',
    value: 'MN',
  },
  {
    label: 'Mississippi',
    value: 'MS',
  },
  {
    label: 'Missouri',
    value: 'MO',
  },
  {
    label: 'Montana',
    value: 'MT',
  },
  {
    label: 'Nebraska',
    value: 'NE',
  },
  {
    label: 'Nevada',
    value: 'NV',
  },
  {
    label: 'New Hampshire',
    value: 'NH',
  },
  {
    label: 'New Jersey',
    value: 'NJ',
  },
  {
    label: 'New Mexico',
    value: 'NM',
  },
  {
    label: 'New York',
    value: 'NY',
  },
  {
    label: 'North Carolina',
    value: 'NC',
  },
  {
    label: 'North Dakota',
    value: 'ND',
  },
  {
    label: 'Northern Mariana Islands',
    value: 'MP',
  },
  {
    label: 'Ohio',
    value: 'OH',
  },
  {
    label: 'Oklahoma',
    value: 'OK',
  },
  {
    label: 'Oregon',
    value: 'OR',
  },
  {
    label: 'Palau',
    value: 'PW',
  },
  {
    label: 'Pennsylvania',
    value: 'PA',
  },
  {
    label: 'Puerto Rico',
    value: 'PR',
  },
  {
    label: 'Rhode Island',
    value: 'RI',
  },
  {
    label: 'South Carolina',
    value: 'SC',
  },
  {
    label: 'South Dakota',
    value: 'SD',
  },
  {
    label: 'Tennessee',
    value: 'TN',
  },
  {
    label: 'Texas',
    value: 'TX',
  },
  {
    label: 'Utah',
    value: 'UT',
  },
  {
    label: 'Vermont',
    value: 'VT',
  },
  {
    label: 'Virgin Islands',
    value: 'VI',
  },
  {
    label: 'Virginia',
    value: 'VA',
  },
  {
    label: 'Washington',
    value: 'WA',
  },
  {
    label: 'West Virginia',
    value: 'WV',
  },
  {
    label: 'Wisconsin',
    value: 'WI',
  },
  {
    label: 'Wyoming',
    value: 'WY',
  },
];

export const defaultValues = {
  firstName: '',
  lastName: '',
  email: '',
  phone: '',
  city: '',
  state: '',
  zip: '',
  addressLine1: '',
  addressLine2: '',
  gender: '',
  dob: '',
  emergencyContactName: '',
  emergencyContactNumber: '',
  emergencyContactRelationship: '',
  id: undefined,
};

export const formatPatient = ({ name, telecom, address, birthDate, gender, contact, id }: Patient) => ({
  firstName: name[0].given.join(' '),
  lastName: name[0].family,
  email: telecom.find((t) => t.system === 'email')?.value ?? '',
  phone: telecom.find((t) => t.system === 'phone')?.value ?? '',
  city: address?.[0].city || '',
  state: address?.[0].state || '',
  zip: address?.[0].postalCode || '',
  addressLine1: address?.[0].line?.[0] || '',
  addressLine2: address?.[0].line?.[1] || '',
  gender,
  dob: birthDate,
  emergencyContactName: contact ? contact?.[0]?.name.given.join(' ') : '',
  emergencyContactLastName: contact ? contact?.[0]?.name.family : '',
  emergencyContactNumber: contact?.[0]?.telecom?.find((t) => t.system === 'phone')?.value ?? '',
  emergencyContactRelationship: contact?.[0]?.relationship?.[0]?.text ?? '',
  id,
});

export const createPatientBody = ({
  firstName,
  lastName,
  email,
  phone,
  city,
  state,
  zip,
  addressLine1,
  addressLine2,
  gender,
  dob,
  emergencyContactName,
  emergencyContactLastName,
  emergencyContactNumber,
  emergencyContactRelationship,
  updatedBy,
  id,
  bypassAddressValidation = false,
}: {
  firstName: string;
  lastName: string;
  email: string;
  phone: string;
  city: string;
  state: string;
  zip: string;
  addressLine1: string;
  addressLine2: string;
  gender: string;
  dob: string;
  emergencyContactName: string;
  emergencyContactLastName: string;
  emergencyContactNumber: string;
  emergencyContactRelationship: string;
  updatedBy: string;
  id?: string;
  bypassAddressValidation: boolean;
}) => ({
  id,
  name: [
    {
      given: [firstName],
      family: lastName,
    },
  ],
  telecom: [
    {
      system: 'email',
      value: email,
    },
    {
      system: 'phone',
      value: phone,
    },
  ],
  address: [
    {
      city,
      state,
      postalCode: zip,
      country: 'United States of America',
      line: [addressLine1, addressLine2],
    },
  ],
  gender,
  birthDate: dob,
  bypassAddressValidation,
  updatedBy,
  ...(emergencyContactName && {
    contact: [
      {
        name: {
          given: [emergencyContactName],
          family: emergencyContactLastName,
        },
        relationship: [
          {
            text: emergencyContactRelationship,
            coding: [
              {
                display: emergencyContactRelationship,
                userSelected: true,
              },
            ],
          },
        ],
        telecom: [
          {
            system: 'phone',
            value: emergencyContactNumber,
          },
        ],
      },
    ],
  }),
});
