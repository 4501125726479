import gql from 'graphql-tag';

export const GET_RESULT_REPORT = gql`
  mutation GetResultReport($kitId: String!, $patientId: String!) {
    getResultReport(kitId: $kitId, patientId: $patientId)
  }
`;

export const GET_PDF_RESULT = gql`
  mutation GetPDFResult($kitId: String!) {
    getPDFResult(kitId: $kitId)
  }
`;
