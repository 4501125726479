import gql from 'graphql-tag';

export const GET_CELIAC_KITS_REPORT = gql`
query GetCeliacKitsReport {
    getCeliacReport {
      kitId
      activationTime
      activationId
    }
  }
`;