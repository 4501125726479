import gql from 'graphql-tag';

export const GET_STI_REPORT = gql`
  query GetStiReport {
    getStiReport {
      kitId
      patientFirstName
      patientLastName
      patientEmail
      patientState
      testType
      testStatus
      testInterpretation
      positiveBiomarkers
      resultedTimestamp
    }
  }
`;
