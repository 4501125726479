import styled from 'styled-components';

interface ButtonProps {
  secondary?: boolean;
  styles?: {
    padding?: string;
    fontSize?: string;
    lineHeight?: string;
    opacity?: number;
  };
}

export default styled.button<ButtonProps>`
  border-radius: 100px;
  background: ${({ secondary }) => (secondary ? 'white' : 'black')};
  color: ${({ secondary }) => (secondary ? 'black' : 'white')};
  padding: ${({ styles }) => styles?.padding || '15px 88px'};
  border: 1px solid #000000;
  font-family: Lato;
  font-weight: bold;
  font-size: ${({ styles }) => styles?.fontSize || '14px'};
  line-height: ${({ styles }) => styles?.lineHeight || '13px'};
  opacity: ${({ styles }) => styles?.opacity || 1};
  :hover {
    cursor: pointer;
  }
`;
