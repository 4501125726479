import styled from 'styled-components';

export const SearchBarContainer = styled.div`
  display: flex;
  margin: 30px 0 20px 24px;
  flex-direction: column;
`;

export const SearchButton = styled.button`
  background: black;
  color: white;
  border-radius: 4px;
  font-family: Roboto;
  font-style: normal;
  font-size: 14px;
  line-height: 24px;
  height: 42px;
  width: 133px;
  border: none;
  margin-left: 10px;
  :hover {
    cursor: pointer;
  }
  margin-left: 30px
`;
