import * as Sentry from '@sentry/react';
import { BrowserTracing } from '@sentry/tracing';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import { useFlags } from 'launchdarkly-react-client-sdk';

import SearchPage from 'containers/SearchPage';
import ReportPage from 'containers/ReportPage';
import PatientProfilePage from 'containers/PatientProfilePage';
import CreatePatientPage from 'containers/CreatePatientPage';
import ProtectedRoute from 'components/ProtectedRoute';
import SentryDebug from 'containers/SentryDebug';

// yarn sentry-cli releases files 1.0-local-partner-portal upload-sourcemaps ./build --project partner-portal-frontend --org imaware
const sentryRelease =
  window?.config?.REACT_APP_RELEASE_TAG ?? process.env.REACT_APP_RELEASE_TAG;
const sentryEnvironment =
  window?.config?.REACT_APP_ENVIRONMENT ?? process.env.REACT_APP_ENVIRONMENT;
Sentry.init({
  dsn: 'https://dae1ef736db94dbc857bb99252f6b776@o1212911.ingest.sentry.io/6402980',
  integrations: [new BrowserTracing()],
  tracesSampleRate: 1.0,
  enabled: !!sentryRelease, // turn off Sentry for local development by default
  release: sentryRelease || '1.0-local-partner-portal',
  environment: sentryEnvironment || 'local',
  ignoreErrors: ['Patient creation was unsuccessful:'],
});

function App() {
  const { enablePatientReports } = useFlags();

  return (
    <Router>
      <Switch>
        <Route path="/debug-sentry" component={SentryDebug} />
        <ProtectedRoute path="/create-patient" component={CreatePatientPage} />
        <ProtectedRoute
          path="/profile/:patientId"
          component={PatientProfilePage}
        />
        {enablePatientReports ? (
          <ProtectedRoute path="/reports" component={ReportPage} />
        ) : null}
        <ProtectedRoute path="/" component={SearchPage} />
      </Switch>
    </Router>
  );
}

export default App;
