type JsonValue = string | number | boolean | null | undefined | JsonObject | JsonValue[];

export interface JsonObject {
  [key: string]: JsonValue;
}
export interface CsvHeader {
  label: string;
  valuePath: string;
}
const jsonToCsv = (json: JsonObject[], headers?: CsvHeader[]): string => {
  let keys: string[] = [];
  let header = '';

  if (headers && headers.length > 0) {
    keys = headers.map((h) => h.valuePath);
    header = headers.map((h) => h.label).join(',') + '\n';
  } else {
    keys = Object.keys(json[0]);
    header = keys.join(',') + '\n';
  }

  const rows = json
    .map((obj) => {
      return keys
        .map((key) => {
          const val = obj[key];
          if (typeof val === 'object' && val !== null) {
            return JSON.stringify(val);
          } else {
            return val;
          }
        })
        .join(',');
    })
    .join('\n');
  return header + rows;
};
export const downloadCsvFile = (json: JsonObject[], headers?: CsvHeader[]) => {
  const csvData: string = jsonToCsv(json, headers);
  const blob: Blob = new Blob([csvData], { type: 'text/csv' });
  const url: string = URL.createObjectURL(blob);
  const link: HTMLAnchorElement = document.createElement('a');
  link.setAttribute('href', url);
  link.setAttribute('download', 'data.csv');
  link.click();
  link.remove();
};
