import { gql } from '@apollo/client';

const SEARCH_PATIENT_FIELDS = `
  id
  name {
    family
    given
  }
  telecom {
    system
    value
  }
  address {
    city
    state
    postalCode
    country
    line
  }
  gender
  birthDate
  testHistory {
    testType
    kitId
    activationDate
    trackingPatient {
      carrier
      trackingNumber
    }
    trackingLab {
      carrier
      trackingNumber
    }
    kitStatus {
      status
      timestamp
    }
  }

`;

export const WRITE_CACHED_RESULTS = gql`
  query WriteSearchResults {
    searchPatient {
       ${SEARCH_PATIENT_FIELDS}
    }
  }
`;

export const READ_CACHED_RESULTS = gql`
  query GetSearchResults {
    searchPatient {
      ${SEARCH_PATIENT_FIELDS}
    }
  }
  `;

export const SEARCH_PATIENT = gql`
  mutation SearchPatient($searchParams: SearchPatientParams!) {
    searchPatient(searchParams: $searchParams) {
       ${SEARCH_PATIENT_FIELDS}
    }
  }
`;
